import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@chakra-ui/react';
import { TimelineItem } from './TimelineItem';

export const TimelineItems = ({ children, direction, ...rest }) => {
    const countChildren = React.Children.count(children);
    const childrenClones = React.Children.map(children, (element, index) =>
        React.cloneElement(element, {
            isLast: index === countChildren - 1,
        })
    );

    return (
        <Stack spacing={0} direction={direction} {...rest}>
            {childrenClones}
        </Stack>
    );
};

TimelineItems.propTypes = {
    children: PropTypes.instanceOf(Object),
    direction: PropTypes.oneOfType(PropTypes.string, PropTypes.instanceOf(Object))
};

TimelineItems.defaultProps = {
    children: <TimelineItem />,
    direction: "vertical"
};
