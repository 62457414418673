import React from 'react';
import { useField } from '@formiz/core';
import { Input, InputGroup, Textarea } from '@chakra-ui/react';
import { FormGroup } from 'components';
import PropTypes from 'prop-types';

/**
 * Composant select sous forme de menu déroulant ou de recherche suivant le nombre de maxOptions
 * @author Cavelier Mickael <mickael.cavelier@wyzengroup.com>
 * @param {Object} props
 *
 * @param {string} props.label - Label accompagnant le champ
 *
 * @param {Object} props.type - Type du champ (text, number...)
 *
 * @param {bool} props.required - Indique si le champ est requis ou non
 *
 * @param {bool} props.isDisabled - Indique si le champ est désactivé ou non
 *
 * @param {string} props.placeholder - Valeur proposé à l'utilisateur dans le champ
 *
 * @param {Object} props.children - Children qui peut etre injecté à côté du champ
 * @return
 */
export const FieldInputShowFile = (props) => {
  const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(
    props
  );
  const {
    label,
    type,
    required,
    isDisabled,
    placeholder,
    children,
    ...rest
  } = props;

  const showError = !isValid && isSubmitted;

  const formGroupProps = {
    errorMessage,
    id,
    isRequired: !!required,
    label,
    showError,
    isDisabled,
    ...rest,
  };

  const getElementAndProps = () => {
    if (type === 'textarea') {
      return [
        Textarea,
        {
          height: '1.8rem',
        },
      ];
    }
    return [Input, {}];
  };

  const [Element, inputExtraProps] = getElementAndProps();

  return (
    <FormGroup {...formGroupProps}>
      <InputGroup>
        {children}
        <Element
          border="none"
          fontSize="sm"
          id={id}
          type={type || 'text'}
          value={value ?? ''}
          onChange={(e) => setValue(e.target.value)}
          focusBorderColor="brand.700"
          placeholder={placeholder}
          aria-invalid={showError}
          aria-required={!!required}
          aria-describedby={showError ? `${id}-error` : null}
          {...inputExtraProps}
        />
      </InputGroup>
    </FormGroup>
  );
};

FieldInputShowFile.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  children: PropTypes.instanceOf(Object),
};
FieldInputShowFile.defaultProps = {
  label: undefined,
  type: 'text',
  required: false,
  isDisabled: false,
  placeholder: 'Saisissez votre message',
  children: <></>,
};
