import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, useStyleConfig } from '@chakra-ui/react';
import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import { FormGroup } from 'components';
import { FaCaretDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { AlertError } from 'components/alert/AlertError';
import { InputSkeleton } from 'components/skeleton-loader/_partials/InputSkeleton';

const propTypes = {
  label: PropTypes.node,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.node,
      label: PropTypes.node,
    })
  ),
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  iconColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  ...fieldPropTypes,
};

const defaultProps = {
  label: '',
  helper: '',
  placeholder: 'Sélectionnez une option',
  isDisabled: false,
  options: [],
  isError: false,
  isLoading: false,
  backgroundColor: 'gray.100',
  iconColor: 'brandSecondary.500',
  ...fieldDefaultProps,
};

export const FieldSelect = (props) => {
  const {
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    errorMessage,
  } = useField(props);

  const {
    label,
    isDisabled,
    required,
    placeholder,
    options,
    setParentValue,
    isError,
    isLoading,
    iconColor,
    backgroundColor,
    ...otherProps
  } = props;
  const { t } = useTranslation();
  const [isTouched, setIsTouched] = React.useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const handleBlur = () => {
    setIsTouched(true);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    if (typeof setParentValue !== 'undefined') {
      setParentValue(e.target.value);
    }
  };

  const formGroupProps = {
    errorMessage,
    id,
    isRequired: !!required,
    label,
    showError,
    isDisabled,
    ...otherProps,
  };

  const styles = useStyleConfig('FieldSelect');

  return (
    <FormGroup {...formGroupProps}>
      {isError && <AlertError message={t('app.core.form.load_error')} />}
      {isLoading && <InputSkeleton />}

      {!isError && !isLoading && (
        <Select
          icon={<FaCaretDown />}
          id={id}
          key={resetKey}
          defaultValue={value}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={placeholder}
          aria-invalid={showError}
          aria-describedby={!isValid ? `${id}-error` : null}
          disabled={isDisabled}
          backgroundColor={styles?.backgroundColor ?? backgroundColor}
          iconColor={styles?.iconColor ?? iconColor}
        >
          {options?.length &&
            options.map(({ value: v, label: l }) => (
              <option key={v} value={v}>
                {t(l, { defaultValue: l })}
              </option>
            ))}
        </Select>
      )}
    </FormGroup>
  );
};
FieldSelect.propTypes = propTypes;
FieldSelect.defaultProps = defaultProps;
