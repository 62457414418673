/* eslint-disable no-console */
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { APPENV } from 'config/config';
import { firebaseConfig } from './firebase.conf';

// console.info(firebaseConfig);
firebase.initializeApp(firebaseConfig);

export const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url: `${APPENV.HOST_FRONT}/login`,
  // This must be true.
  handleCodeInApp: true,
  // iOS: {
  //   bundleId: 'com.example.ios'
  // },
  // android: {
  //   packageName: 'com.example.android',
  //   installApp: true,
  //   minimumVersion: '12',
  // },
};
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const reauthenticate = (currentPassword) => {
  const user = auth.currentUser;
  const cred = firebase.auth.EmailAuthProvider.credential(
    user.email,
    currentPassword
  );
  return user.reauthenticateWithCredential(cred);
};
export const getCurrentUser = () => auth.currentUser;
export const updatePassword = (password) =>
  auth.currentUser.updatePassword(password);
export const isSignInWithEmailLink = (url) => auth.isSignInWithEmailLink(url);
export const signInWithEmailLink = (email, url) =>
  auth.signInWithEmailLink(email, url);
export const sendSignInLinkToEmail = (email) =>
  auth.sendSignInLinkToEmail(email, actionCodeSettings);
export const sendPasswordResetEmail = (email) =>
  auth.sendPasswordResetEmail(email, actionCodeSettings);
export const createUserWithEmailAndPassword = (email, password) =>
  auth.createUserWithEmailAndPassword(email, password);
export const signInWithEmailPassword = (email, password) =>
  auth.signInWithEmailAndPassword(email, password);
export const linkWithPopup = (provider) =>
  auth.currentUser.linkWithPopup(provider);
export const signInWithPopup = (provider) => {
  provider.setCustomParameters({
    prompt: 'select_account',
  });
  return auth.signInWithPopup(provider);
};

export const signOut = () => {
  auth.signOut();
};
