import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from 'Routes';
import { CheckNewRelease } from 'components/check-new-release';
import { NotificationCookies } from 'components/notification-cookies';
import 'components/formulaire/style.css';
import 'layout/pagination.css';
import { ViewportHeight } from 'layout';
import { APPENV } from 'config/config';

export const App = () => {
  useEffect(() => {
    document.title = APPENV.APP_NAME;
  });
  return (
    <>
      <Router>
        <ViewportHeight>
          <Routes />
        </ViewportHeight>
      </Router>
      <CheckNewRelease />
      <NotificationCookies jours={30} />
    </>
  );
};
