import {
    Text,
    Stack,
    HStack,
} from '@chakra-ui/react';
import { Formiz, useForm } from '@formiz/core';
import { FieldInput, FieldSelect } from 'components';
import { FieldInputTextArea } from 'components/fields/FieldInputTextArea';
import { FormBox } from 'components/formulaire/FormBox';
import { SubGroup } from 'components/subgroup/SubGroup';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonDelete } from 'components/button';
import { isMinLength, isNumber } from '@formiz/validations';
import { ButtonSubmit } from 'components/button/ButtonSubmit';
import { useAuthentificationContext } from 'context/auth-context';
import { useCreateProduitDemain } from 'app/formulaire/services/chimie-demain/useCreateProduitDemain';
import { useUpdateProduitDemain } from 'app/formulaire/services/chimie-demain/useUpdateProduitDemain';
import { useDeleteProduitDemain } from 'app/formulaire/services/chimie-demain/useDeleteProduitDemain';
import { FieldReactSelect } from 'components/fields/FieldReactSelect';
import { Countries } from 'app/formulaire/functions/Countries';

export const FormChimieDemain = ({ produit }) => {
    const form = useForm({ subscribe: 'form' });

    const { user } = useAuthentificationContext();

    const [typeZone, setTypeZone] = useState(produit?.zoneFabrication ?? undefined);
    const [typePays, setTypePays] = useState(produit?.paysFabrication ?? undefined);
    const [enjeuStrategique, setEnjeuStrategique] = useState(produit?.enjeuStrategique ?? undefined);

    useEffect(() => {
        if (typeZone === 'tbd') {
            setTypePays('TBD');
        } else {
            setTypePays(produit?.paysFabrication);
        }
    }, [typeZone, form, produit]);



    const optionsUniteVolConsomme = [
        { value: 'tonnes', label: 'Tonnes' },
        { value: 'metre_cube', label: 'm³' },
    ];

    const optionsZone = [
        { value: 'asie', label: 'Asie' },
        { value: 'afrique', label: 'Afrique' },
        { value: 'europe', label: 'Europe' },
        { value: 'amerique_nord', label: 'Amérique du Nord' },
        { value: 'amerique_sud', label: 'Amérique du Sud' },
        { value: 'oceanie', label: 'Océanie' },
        { value: 'moyen_orient', label: 'Moyen-Orient' },
        { value: 'tbd', label: 'Indéterminée' }
    ];

    const optionsModeApprovisionnement = [
        { value: 'fluvial', label: 'Maritime' },
        { value: 'maritime', label: 'Fluvial' },
        { value: 'fer', label: 'Fer' },
        { value: 'route', label: 'Route' },
    ];

    const optionsModeApprovisionnementSecondaire = [
        { value: 'fluvial', label: 'Maritime' },
        { value: 'maritime', label: 'Fluvial' },
        { value: 'fer', label: 'Fer' },
        { value: 'route', label: 'Route' },
        { value: 'pipe', label: 'Pipe' },
    ];

    const optionsConditionnement = [
        { value: 'vrac_liquide', label: 'Vrac liquide' },
        { value: 'vrac_sec', label: 'Vrac sec' },
        { value: 'conteneur_dry', label: 'Conteneur Dry' },
        { value: 'isotank', label: 'Isotank' },
        { value: 'groupage', label: 'Groupage' },
        { value: 'wagon', label: 'Wagon/UTI' },
    ];

    const optionsEnjeuxStrategiques = [
        { value: 'decarbonation', label: 'Décarbonation process' },
        { value: 'molecules', label: 'Molécules biosourcées' },
        { value: 'autre', label: 'Autres' },
    ];

    const optionsBool = [
        { label: 'Oui', value: 'true' },
        { label: 'Non', value: 'false' },
    ];

    const { mutate: mutationCreate, isLoading: isLoadingMutateCreate } = useCreateProduitDemain({});
    const { mutate: mutationUpdate, isLoading: isLoadingMutateUpdate } = useUpdateProduitDemain({ id: produit?.id, userId: user?.utilisateurId });
    const { mutate: mutationDelete } = useDeleteProduitDemain({ id: produit?.id, userId: user?.utilisateurId });

    const removeProduit = () => {
        mutationDelete();
    };

    const countries = Countries.getCountries();
    const countriesFabr = Countries.getCountries();

    countriesFabr.push({ value: 'TBD', label: 'Indéterminé' });

    const handleSubmit = (values) => {
        let datas = {};
        if (values?.first_number_cas === null || values?.second_number_cas === null || values?.third_number_cas === null) {

            datas = { ...values };
        } else {
            const casNumber = `${values?.first_number_cas}-${values?.second_number_cas}-${values?.third_number_cas}`;
            datas = { ...values, casNumber };
        }

        if (produit?.id !== undefined) {
            mutationUpdate(datas);
        } else {
            mutationCreate(datas);
        }
    };

    return (
        <Formiz autoForm connect={form} onValidSubmit={handleSubmit}>
            <Stack spacing={4}>
                <Stack spacing={8}>
                    <FormBox
                        title="questionnaire:chapters.descriptionProduit"
                        actionHeader={
                            user?.canUpdate && (
                                <ButtonDelete
                                    label="Supprimer"
                                    action={() => removeProduit(produit)}
                                />
                            )}
                    >
                        <Stack spacing={4}>
                            <HStack>
                                <SubGroup title="Number CAS">
                                    <HStack alignItems="self-start">
                                        <FieldInput
                                            name="first_number_cas"
                                            maxLength={7}
                                            minLength={2}
                                            textAlign="right"
                                            w={{ base: "40%", md: "20%" }}
                                            numericOnly
                                            validations={[
                                                {
                                                    rule: isNumber(),
                                                    message: 'Doit etre un nombre',
                                                },
                                                {
                                                    rule: isMinLength(2),
                                                    message: 'Minimum 2 chiffres',
                                                },
                                            ]}
                                            defaultValue={produit?.casNumber?.split('-')[0]}
                                        />
                                        <Stack>
                                            <Text> - </Text>
                                        </Stack>
                                        <FieldInput
                                            name="second_number_cas"
                                            maxLength={2}
                                            minLength={2}
                                            textAlign="right"
                                            w={{ base: "20%", md: "13%" }}
                                            numericOnly
                                            validations={[
                                                {
                                                    rule: isNumber(),
                                                    message: 'Doit etre un nombre',
                                                },
                                                {
                                                    rule: isMinLength(2),
                                                    message: 'Minimum 2 chiffres',
                                                },
                                            ]}
                                            defaultValue={produit?.casNumber?.split('-')[1]}

                                        />
                                        <Text> - </Text>
                                        <FieldInput
                                            name="third_number_cas"
                                            maxLength={1}
                                            minLength={1}
                                            textAlign="right"
                                            w={{ base: '15%', md: "8%" }}
                                            numericOnly
                                            validations={[
                                                {
                                                    rule: isNumber(),
                                                    message: 'Doit etre un nombre',
                                                },
                                                {
                                                    rule: isMinLength(1),
                                                    message: 'Minimum 1 chiffre',
                                                },
                                            ]}
                                            defaultValue={produit?.casNumber?.split('-')[2]}

                                        />
                                    </HStack>
                                </SubGroup>
                            </HStack>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldInput name="nom_technique" label="Nom technique" defaultValue={produit?.nomTechnique} />
                            </Stack>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldInput name="purete" label="Pureté (%)" numericOnly withPercentage defaultValue={produit?.purete} />
                                <FieldInput name="concentration" label="Concentration (%)" numericOnly withPercentage defaultValue={produit?.concentration} />
                            </Stack>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldSelect
                                    name="enjeu_strategique"
                                    label="Enjeu stratégique visé avec ce produit"
                                    options={optionsEnjeuxStrategiques}
                                    defaultValue={enjeuStrategique}
                                    setParentValue={setEnjeuStrategique}
                                />
                            </Stack>
                            {enjeuStrategique === "autre" && (
                                <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                    <FieldInputTextArea name="enjeu_strategique_autre" label="Veuillez préciser" defaultValue={produit?.enjeuStrategiqueAutre} />
                                </Stack>
                            )}
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldInput name="volume_potentiel_consomme_annuel" label="Volumes potentiels consommés/an" defaultValue={produit?.volumePotentielConsommeAnnuel} />
                                <FieldSelect
                                    name="volume_consomme_unite"
                                    label="Unité du vol. conso. (tonnes ou m3)"
                                    options={optionsUniteVolConsomme}
                                    defaultValue={produit?.volumeConsommeUnite}
                                />
                            </Stack >
                            <SubGroup title="Zone de fabrication">
                                <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                    <FieldSelect name="zone_fabrication" label="Zone" options={optionsZone} setParentValue={setTypeZone} defaultValue={typeZone} />
                                    <FieldReactSelect key={typePays} name="pays_fabrication" label="Pays" options={countriesFabr} defaultValue={typePays} />

                                    <FieldInput name="ville_fabrication" label="Ville" defaultValue={produit?.villeFabrication} />
                                </Stack>
                            </SubGroup>
                        </Stack>
                    </FormBox>
                    <FormBox title="questionnaire:chapters.approvisionnement">
                        <Stack spacing={4}>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }} w={{ base: '100%', md: '50%' }}>
                                <FieldSelect
                                    name="mode_approvisionnement_principal"
                                    label="Mode d'approvisionnement principal (arrivée en Europe/France)"
                                    options={optionsModeApprovisionnement}
                                    defaultValue={produit?.modeApprovisionnementPrincipal}
                                />
                            </Stack>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldReactSelect name="pays_entree_europe" label="Pays d'entrée en Europe" options={countries} defaultValue={produit?.paysEntreeEurope} />
                                <FieldInput name="ville_entree_europe" label="Ville d'entrée en Europe" defaultValue={produit?.villeEntreeEurope} />
                            </Stack>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldSelect
                                    name="mode_approvisionnement_secondaire"
                                    label="Mode d'approvisionnement secondaire (arrivée sur site)"
                                    options={optionsModeApprovisionnementSecondaire}
                                    defaultValue={produit?.modeApprovisionnementSecondaire}
                                />
                                <FieldSelect
                                    name="conditionnement"
                                    label="Conditionnement (Déf. Groupage : regroupement dans un seul chargement de produits appartenant à plusieurs clients)"
                                    options={optionsConditionnement}
                                    defaultValue={produit?.conditionnement}
                                />
                            </Stack>
                        </Stack>
                    </FormBox>

                    <FormBox title="questionnaire:chapters.evolution">
                        <Stack spacing={4}>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldSelect
                                    name="mutualisation_approvisionnement"
                                    label="Seriez-vous ouvert à une mutualisation de votre approvisionnement sur ce produit ?"
                                    options={optionsBool}
                                    defaultValue={produit?.mutualisationApprovisionnement}
                                />
                                <FieldSelect
                                    name="approvisionnement_local"
                                    label="Envisageriez-vous de vous fournir localement (Normandie) ?"
                                    options={optionsBool}
                                    defaultValue={produit?.approvisionnementLocal}
                                />
                            </Stack>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldInputTextArea
                                    name="contraintes_mutualisation"
                                    label="Quelles seraient les contraintes pour la mise en place de cette mutualisation ?"
                                    defaultValue={produit?.contraintesMutualisation}
                                />
                                <FieldInputTextArea
                                    name="contraintes_approvisionnement"
                                    label="Quelles sont les contraintes pour vous approvisionner en local ?"
                                    defaultValue={produit?.contraintesApprovisionnement}
                                />
                            </Stack>
                        </Stack>
                    </FormBox>
                </Stack>
                {user?.canUpdate && (
                    <Stack alignItems="flex-end">
                        <ButtonSubmit
                            label="Enregistrer"
                            onClick={form.submit}
                            iconOnly={false}
                            disabled={!form.isValid}
                            isLoading={isLoadingMutateCreate || isLoadingMutateUpdate}
                        />
                    </Stack>
                )}
            </Stack>
        </Formiz>
    );
};

FormChimieDemain.propTypes = {
    produit: PropTypes.instanceOf(Object),
};

FormChimieDemain.defaultProps = {
    produit: undefined,
};
