/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Flex, Grid, Heading } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';
import { FaArrowLeft } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

export const HeaderLayout = ({ to, title, cursor, ...rest }) => {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <Flex
            justifyContent="center"
            placeItems="center"
            borderBottom="1px"
            borderColor="gray.300"
            boxShadow="sm"
            zIndex="99"
            backgroundColor="gray.100"
            // position="fixed"
            // top={0}
            // left={{ base: '0', md: '70px' }}

            as={Box}
            width="100%"
            height="70px"
            {...rest}
        >
            <Flex
                alignItems="center"
                cursor={cursor}
                onClick={
                    to !== ''
                        ? () => {
                            history.push(to);
                        }
                        : () => { }
                }
            >
                {to && (
                    <>
                        <Box
                            rounded="0.3rem"
                            p="0.5rem"
                            _hover={{
                                backgroundColor: 'gray.50',
                            }}
                        >
                            <Box h="1.5rem" w="1.5rem" as={FaArrowLeft} color="green.800" />
                        </Box>
                    </>
                )}
                <Heading
                    mr={4}
                    fontSize={{
                        base: 'sm',
                        sm: 'md',
                        md: 'lg',
                        lg: 'lg',
                        xl: 'xl',
                    }}
                >
                    {`${t(title)}`}
                </Heading>
            </Flex>
            <Grid alignItems="flex-end" placeItems="flex-end" />
        </Flex>
    );
};

HeaderLayout.propTypes = {
    title: PropTypes.string.isRequired,
    to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    cursor: PropTypes.string,
};
HeaderLayout.defaultProps = {
    cursor: '',
    to: '',
};
