import React from 'react';
import { Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const Titre = ({ children, size, color, ...props }) => {
  let fontSize = '';
  switch (size) {
    case 'h1':
      fontSize = '5xl';
      break;

    case 'h2':
      fontSize = '4xl';
      break;

    case 'h3':
      fontSize = '3xl';
      break;

    case 'h4':
      fontSize = '2xl';
      break;
    case 'h5':
      fontSize = 'xl';
      break;
    case 'h6':
      fontSize = 'lg';
      break;
    case 'h7':
      fontSize = 'md';
      break;
    case 'h8':
      fontSize = 'sm';
      break;
    case 'h9':
      fontSize = 'xs';
      break;
    default:
      fontSize = '5xl';
      break;
  }

  return (
    <Text marginBottom="5px" color={color} fontSize={fontSize} {...props}>
      {children}
    </Text>
  );
};

Titre.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node]).isRequired,
};

Titre.defaultProps = {
  color: 'gray.800',
  size: '5xl',
};
