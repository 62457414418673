import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { PageFormulaire } from 'app/formulaire/page/PageFormulaire';


export const FormulaireRoutes = () => {
    const { path } = useRouteMatch();

    return (
        <Route
            exact
            path={`${path}`}
            component={PageFormulaire}
        />
    );
}
