import React from 'react';

import { LayoutEmpty } from 'layout/LayoutEmpty';
import { Box } from '@chakra-ui/react';
import { Titre } from 'components/heading/Titre';

export const PageProtectionDonnees = () => (
  <LayoutEmpty>
    <Titre textAlign="center">
      Charte sur la protection des données personnelles
    </Titre>
    <Box borderWidth={1} borderColor="red.200" />
  </LayoutEmpty>
);
