import { Titre } from 'components/heading/Titre';
import { LayoutEmpty } from 'layout/LayoutEmpty';
import { PageHeader } from 'layout/page-header';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

export const UnAuthorized = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const handleBack = () => {
    history.push({
      pathname: '/home',
    });
  };

  return (
    <LayoutEmpty>
      <PageHeader
        onGoBack={() => handleBack()}
        title="title.accueil"
        cursor="pointer"
        mx="auto"
      />
      <Titre color="orange">{t('unauthorized')}</Titre>
    </LayoutEmpty>
  );
};
