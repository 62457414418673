import React from 'react';
import PropTypes from 'prop-types';
// import { useAuthentificationContext } from 'context/auth-context';
import { NavbarDefault } from './NavbarDefault';

export const Navbar = ({
  isSidebarOpen,
  setIsSidebarOpen,
  isSmallDevice,
  ...props
}) => 
  // const { user } = useAuthentificationContext();

   (
    <>
     
        <NavbarDefault
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          isSmallDevice={isSmallDevice}
          {...props}
        />
    </>
  )
;

Navbar.propTypes = {
  isSidebarOpen: PropTypes.bool,
  setIsSidebarOpen: PropTypes.func,
  isSmallDevice: PropTypes.bool,
};

Navbar.defaultProps = {
  isSidebarOpen: false,
  setIsSidebarOpen: () => {},
  isSmallDevice: false,
};
