import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { AlertInfo } from 'components';

export const NoResult = (props) => {
  const { t } = useTranslation();
  const { message } = props;

  return <AlertInfo message={t(message)} colorScheme="gray" />;
};
NoResult.propTypes = {
  message: PropTypes.string,
};
NoResult.defaultProps = {
  message: 'app.components.noresult',
};
