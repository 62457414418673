import React from 'react';
import { Stack, Text, HStack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { Card } from 'components/card/Card';
import { CardHeader } from 'components/card/CardHeader';
import { CardTitle } from 'components/card/CardTitle';
import { useTranslation } from 'react-i18next';
import { CardBody } from 'components/card/CardBody';
import { Badge } from 'components/badge';

export const FormBox = ({
  title,
  varTitle,
  children,
  actionHeader,
  moreText,
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      spacing={4}
      align={{
        base: 'center',
        sm: 'center',
        md: 'center',
        lg: 'normal',
      }}
      direction={{
        base: 'column',
        sm: 'column',
        md: 'column',
        lg: 'row',
      }}
    >
      <Card
        borderWidth="0px"
        borderRadius="0"
        boxShadow="none"
        mx="auto"
        textAlign="center"
      >
        <CardHeader>
          <Stack isInline>
            <CardTitle textAlign="left" flexGrow="1">
              <HStack oriention="" spacing={2}>
                <Text>
                  {`${t(title, {
                    defaultValue: 'FormBox',
                    ...varTitle,
                  })}`}
                </Text>
                {moreText !== '' && (
                  <Badge borderRadius={0} size="md">
                    {moreText}
                  </Badge>
                )}
              </HStack>
            </CardTitle>

            {actionHeader}
          </Stack>
        </CardHeader>
        <CardBody p={0}>
          <Stack spacing={4}>{children}</Stack>
        </CardBody>
      </Card>
    </Stack>
  );
};

FormBox.propTypes = {
  moreText: PropTypes.string,
  title: PropTypes.string,
  varTitle: PropTypes.instanceOf(Object),
  children: PropTypes.node,
  actionHeader: PropTypes.node,
};

FormBox.defaultProps = {
  varTitle: {},
  moreText: '',
  title: '',
  children: '',
  actionHeader: '',
};
