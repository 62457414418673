import Axios from 'axios';
import { APPENV } from 'config/config';
import { useMutation } from 'react-query';

/**
 * Update a file
 * @param {*} config
 * @returns
 */
export const useUpdateFileName = (config) =>
  useMutation((data) => {
    const response = Axios.put(`${APPENV.HOST_API}/file/${data?.id}`, data);
    return response;
  }, config);
