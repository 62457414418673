import React from 'react';
import PropTypes from 'prop-types';
import { SimpleGrid } from '@chakra-ui/react';
import { ShowFile } from './ShowFile';

/**
 * Affiche une liste de fichier pour le téléchargement
 */
export const ShowFiles = ({
  files,
  setFiles,
  columns,
  isReadOnly,
  invalidateQuery,
  callbackDelete,
  isOnline,
  params,
  ...rest
}) => (
  <SimpleGrid columns={columns} gap="10px" {...rest}>
    {Object.values(files)?.map((file) => (
      <ShowFile
        callbackDelete={callbackDelete}
        key={file?.id}
        isReadOnly={isReadOnly}
        setFiles={setFiles}
        files={files}
        file={file}
        isOnline={isOnline}
        params={params}
        invalidateQuery={invalidateQuery}
      />
    ))}
  </SimpleGrid>
);

ShowFiles.propTypes = {
  files: PropTypes.arrayOf(Object),
  setFiles: PropTypes.func,
  isReadOnly: PropTypes.bool,
  columns: PropTypes.instanceOf(Object),
  invalidateQuery: PropTypes.func,
  isOnline: PropTypes.bool,
  params: PropTypes.string,
  callbackDelete: PropTypes.func,
};
ShowFiles.defaultProps = {
  files: [],
  setFiles: () => null,
  isReadOnly: true,
  columns: {
    base: 1,
    sm: 1,
    md: 1,
    lg: 2,
    xl: 3,
  },
  invalidateQuery: () => null,
  isOnline: true,
  params: '',
  callbackDelete: () => {},
};
