import React from 'react';
import PropTypes from 'prop-types';

/**
 * Affiche un loader où le children si un chargement est actif ou non
 * @param {*} props
 * @returns
 */
export const SkeletonLoader = (props) => {
  const { isLoading, skeleton, children, ...rest } = props;
  return !isLoading ? React.cloneElement(<>{children}</>, rest) : skeleton;
};

SkeletonLoader.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node]).isRequired,
  skeleton: PropTypes.instanceOf(Object).isRequired,
};
SkeletonLoader.defaultProps = {
  isLoading: true,
};
