import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';

const propTypes = {
  children: PropTypes.node,
  errorMessage: PropTypes.node,
  helper: PropTypes.node,
  id: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.node,
  showError: PropTypes.bool,
  showText: PropTypes.bool,
  labelShowText: PropTypes.string,
  fontSize: PropTypes.string,
  formatValue: PropTypes.instanceOf(Object),
  asyncValidations: PropTypes.instanceOf(Array),
  keepValue: PropTypes.bool,
};
const defaultProps = {
  children: '',
  errorMessage: '',
  helper: '',
  isRequired: false,
  label: '',
  labelShowText: '',
  fontSize: 'sm',
  showError: false,
  showText: false,
  formatValue: {},
  asyncValidations: [],
  keepValue: false,
};

export const FormGroup = ({
  children,
  errorMessage,
  helper,
  id,
  isRequired,
  label,
  showError,
  showText,
  labelShowText,
  ...props
}) => {
  const { formatValue, asyncValidations, keepValue, fontSize, ...rest } = props;

  return (
    <FormControl isInvalid={showError} isRequired={isRequired} {...rest}>
      {!!label && (
        <FormLabel
          htmlFor={id}
          color="gray.600"
          fontSize={fontSize}
          transition="0.2s"
          fontWeight="bold"
        >
          {label}
        </FormLabel>
      )}
      {!!helper && (
        <FormHelperText mt="0" mb="3">
          {helper}
        </FormHelperText>
      )}
      {children}
      {showError && (
        <FormErrorMessage id={`${id}-error`}>{errorMessage}</FormErrorMessage>
      )}

      {showText && !showError && (
        <FormHelperText textAlign="left" mt="3" mb="3">
          {labelShowText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

FormGroup.propTypes = propTypes;
FormGroup.defaultProps = defaultProps;
