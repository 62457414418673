import React from 'react';
import { HStack, Stack } from '@chakra-ui/react';
import { Timeline } from 'components/timeline/Timeline';
import { TimelineItems } from 'components/timeline/TimelineItems';
import { TimelineItem } from 'components/timeline/TimelineItem';
import { LayoutForm } from 'layout/LayoutForm';
import { useEtapeContext } from 'app/formulaire/context/EtapeProvider';
import { CurrentFormController } from './_partials/CurrentFormController';
import { EtapeAction } from './_partials/EtapeAction';

export const PageFormulaire = () => {

    const { etape, setEtape } = useEtapeContext();

    return (
        <LayoutForm title="questionnaire:title">
            <Stack spacing={8}>
                <HStack justifyContent="center">
                    <Timeline direction={{ base: 'column', md: "row" }} w="100%">
                        <TimelineItems direction={{ base: 'column', md: "row" }} w="100%" alignItems="center">
                            <TimelineItem key={1} direction={{ base: 'column', md: "row" }}>
                                <EtapeAction
                                    onClick={() => setEtape(1)}
                                    isActive={etape === 1}
                                    etape="Identification"
                                    bg="gray.200"
                                    color="black" />
                            </TimelineItem>
                            <TimelineItem key={2} direction={{ base: 'column', md: "row" }}>
                                <EtapeAction
                                    onClick={() => setEtape(2)}
                                    isActive={etape === 2}
                                    etape="Chimie actuelle"
                                    bg="defaultTheme.500"
                                    color="white"
                                />
                            </TimelineItem>
                            <TimelineItem key={3} direction={{ base: 'column', md: "row" }}>
                                <EtapeAction
                                    onClick={() => setEtape(3)}
                                    isActive={etape === 3}
                                    etape="Chimie de demain"
                                    bg="green.500"
                                    color="white" />
                            </TimelineItem>
                            <TimelineItem key={4} direction={{ base: 'column', md: "row" }} >
                                <EtapeAction
                                    onClick={() => setEtape(4)}
                                    isActive={etape === 4}
                                    etape="Valorisation"
                                    bg='purple.500'
                                    color='white' />
                            </TimelineItem>
                            <TimelineItem key={5} direction={{ base: 'column', md: "row" }} >
                                <EtapeAction
                                    onClick={() => setEtape(5)}
                                    isActive={etape === 5}
                                    etape="Validation"
                                    bg="gray.200"
                                    color="black" />
                            </TimelineItem>
                        </TimelineItems>
                    </Timeline>
                </HStack>

                <CurrentFormController />
            </Stack>
        </LayoutForm>
    )
}
