import React from 'react';
import { Skeleton, VStack } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
/**
 * Skeleton input form
 * @author <cavelier.mickael@wyzengroup.com>
 * @returns
 */
export const InputSkeleton = () => {
  const { borderRadius } = useTheme();
  const input = (
    <Skeleton
      border="1px"
      borderRadius={borderRadius.small}
      height="2.5rem"
      width="100%"
    />
  );
  return (
    <VStack alignItems="start" width="100%" spacing="15px">
      {input}
    </VStack>
  );
};
