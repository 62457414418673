// AlertError
import { Alert, AlertIcon, AlertDescription } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * @param {Object} props - Ma props
 * @param {string} props.message - Message d'erreur
 */
export const AlertError = (props) => {
  const { message, ...rest } = props;
  const { t } = useTranslation();
  return (
    <Alert colorScheme="red" status="info" {...rest}>
      <AlertIcon />
      <AlertDescription color="gray.700" display="block">
        {t(message)}
      </AlertDescription>
    </Alert>
  );
};
AlertError.propTypes = {
  message: PropTypes.string,
};
AlertError.defaultProps = {
  message: 'app.components.alert.error',
};
