import Axios from 'axios';
import { useQuery } from 'react-query';
import { APPENV } from 'config/config';

export const useGetProduitsDemain = (userId) =>
  useQuery(
    ['produits-demain', { userId }],
    async () => {
      const result = await Axios.get(`${APPENV.HOST_API}/produits-demain`);
      return result?.data;
    },
    { enabled: !!userId },
    []
  );
