import { Box, Button, Flex, Slide, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';

export const NotificationCookies = ({ content, jours, seconds, show }) => {
  const [cookies, setCookies] = useCookies(['cookie']);
  const [cookieShow, setCookieShow] = useState(show);
  const currentTs = Math.round(Date.now() / 1000);

  // Lecture du cookies
  // Si il n'existe pas, afficher le composant, enregistrer son etat
  // Si il existe, regarder son expiration
  //    Si non expiré => null
  //    Sinon afficher le composant, enregistrer son etat
  useEffect(() => {
    const { ts } = cookies;

    if (ts === undefined) {
      setCookieShow(true);
      setCookies('ts', Math.round(Date.now() / 1000) - 1);
    } else if (currentTs > parseInt(ts, 10)) {
      setCookieShow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies]);

  /**
   * On décale la nouvelle apparition du cookie advert
   */
  const handleClickClose = () => {
    let newts = Math.round(Date.now() / 1000);
    newts += seconds;
    newts += jours * 60 * 60 * 24;

    setCookies('ts', newts);
    setCookieShow(false);
  };

  return (
    <Slide direction="bottom" in={cookieShow} style={{ zIndex: 101 }}>
      <Box p="40px" color="white" mt="4" bg="teal.500" rounded="md" shadow="md">
        <Flex
          dir="column"
          flexGrow="initial"
          justifyContent="space-around"
          alignContent="space-evenly"
          alignItems="self-start"
          gridColumnGap={10}
        >
          {content !== null ? (
            content
          ) : (
            <Text color="whiteAlpha" fontSize="xl">
              En poursuivant votre navigation sur ce site, vous acceptez
              l'utilisation de traceurs (cookies) afin de garder votre connexion
              ouverte et réaliser des statistiques anonymes de visites.
            </Text>
          )}
          <Button
            backgroundColor="white"
            color="black"
            onClick={handleClickClose}
          >
            Fermer
          </Button>
        </Flex>
      </Box>
    </Slide>
  );
};

NotificationCookies.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  jours: PropTypes.number,
  seconds: PropTypes.number,
  show: PropTypes.bool,
};

NotificationCookies.defaultProps = {
  content: null,
  jours: 30,
  seconds: 0,
  show: false,
};
