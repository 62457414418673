import {
 DrawerBody, Tab, TabList, TabPanels, Tabs,
} from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';

export const DrawerAppsContentList = ({ children, ...rest }) => (
  <DrawerBody {...rest}>
    <Tabs>
      <TabList>
        {Array.isArray(children) && (
        <>
          {children.map((child) => <Tab key={Math.random()}>{child.props.title}</Tab>)}
        </>
          )}
        {!Array.isArray(children) && (
          <Tab>{children.props.title}</Tab>
        )}
      </TabList>
      <TabPanels>
        {children}
      </TabPanels>
    </Tabs>
  </DrawerBody>
);

DrawerAppsContentList.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Array)]),
};

DrawerAppsContentList.defaultProps = {
    children: <></>,
};
