import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  HStack,
  Icon,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { queryCache } from 'react-query';
import { Formiz, useForm } from '@formiz/core';
import { removeExtensionFilename } from 'functions/file-scope';
import { AiFillFile, AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { FaTrashRestore } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { FieldInputShowFile } from 'components/fields';
import { FormatDate } from 'functions/common-scope/utils';
import { handleDownload } from 'functions/common-scope/files';
import { APPENV } from 'config/config';
import { toastError, toastSuccess } from 'functions/common-scope/toast';
import { useDeleteFile, useUpdateFileName } from 'services/file';
import { ToolsButton } from './_partials/ToolsButton';

export const ShowFile = (props) => {
  const {
    file,
    isReadOnly,
    invalidateQuery,
    isOnline,
    params,
    callbackDelete,
  } = props;

  // Hooks
  const [isEditMode, setEditMode] = useState(false);
  const [isDeleteMode, setDeleteMode] = useState(false);

  const [fileName, setFileName] = useState(file?.fileName ?? file?.name);
  const fileNameWithoutExtension = removeExtensionFilename(fileName);

  // Resize l'image sur mobile
  const imgSize = useBreakpointValue({ base: '25px', md: '50px' });
  const editFileForm = useForm();
  // const { user } = useAuthentificationContext();
  const { t } = useTranslation();

  const [mutationEdit] = useUpdateFileName({
    onError: () => {
      toastError({
        title: t('file.toast.error.titre'),
        description: t('file.toast.error.description.delete'),
      });
    },
    onSuccess: ({ data }) => {
      setFileName(data?.fileName ?? data?.name);
      toastSuccess({
        title: t('file.toast.success.titre'),
        description: t('file.toast.success.description.update'),
      });
    },
  });

  /**
   * Supprime un fichier et lance le callback de suppression associé
   */
  const [mutationDeleteFile] = useDeleteFile({
    onError: () => {
      toastError({
        title: t('file.toast.error.titre'),
        description: t('file.toast.error.description.delete'),
      });
    },
    onSuccess: () => {
      toastSuccess({
        title: t('file.toast.success.titre'),
        description: t('file.toast.success.description.delete'),
      });
      invalidateQuery();
    },
  });

  if (!file || file.length === 0) {
    return null;
  }

  const resetMode = () => {
    setEditMode(false);
    setDeleteMode(false);
  };

  const handleDelete = () => {
    mutationDeleteFile(file?.fichierId ?? file?.id);
    callbackDelete(file?.fichierId ?? file?.id);
  };

  // Change the name of a file
  const handleEdit = ({ nameFile }) => {
    const data = {
      name: nameFile,
      id: file?.fichierId ?? file?.id,
    };
    mutationEdit(data);
    queryCache.invalidateQueries('file', file?.fichierId ?? file?.id);
    setEditMode(false);
  };

  // Download the file
  const downloadFile = () => {
    handleDownload(
      `${APPENV.HOST_API}/file/${file?.fichierId ?? file?.id}${params}`,
      'file',
      isOnline
    );
  };
  return (
    <HStack
      width="100%"
      minW="100%"
      borderRadius="5px"
      backgroundColor="gray.100"
      border="1px"
      borderColor="gray.200"
      boxShadow="xs"
      _hover={{ backgroundColor: 'gray.200' }}
      justifyContent="space-between"
      spacing={0}
    >
      <Formiz connect={editFileForm} onSubmit={handleEdit}>
        <HStack
          borderRadius="5px"
          padding="10px"
          w="100%"
          h="100%"
          onClick={() => {
            if (!isEditMode) downloadFile();
          }}
          cursor="pointer"
        >
          <Icon
            color="blue.400"
            width={imgSize}
            height={imgSize}
            as={AiFillFile}
          />
          {!isEditMode && (
            <VStack gray="500" alignItems="flex-start">
              <Text as="b" fontSize="12px">
                {fileNameWithoutExtension}
              </Text>
              <Text as="i" color="gray.500" fontSize="10px">
                {t('documents.loaded', {
                  date: FormatDate(file.dateCreation),
                })}
              </Text>
            </VStack>
          )}

          {isEditMode && (
            <>
              <VStack>
                <FieldInputShowFile
                  onKeyPress={(event) =>
                    event.key === 'Enter' ? editFileForm.submit() : () => null
                  }
                  defaultValue={fileNameWithoutExtension}
                  name="nameFile"
                />
              </VStack>
              {/* Boutton confirmation update */}
              <Box
                cursor="pointer"
                onClick={editFileForm.submit}
                width="55px"
                height="40px"
                padding="10px"
                rounded="5px"
                as={AiOutlineCheck}
                _hover={{ backgroundColor: 'gray.100' }}
              />

              {/* Bouton annulation update */}
              <Box
                cursor="pointer"
                onClick={resetMode}
                width="55px"
                height="40px"
                padding="10px"
                rounded="5px"
                as={MdClose}
                _hover={{ backgroundColor: 'gray.100' }}
              />
            </>
          )}
        </HStack>
        {/* Boutton confirmation delete */}
        {isDeleteMode && (
          <>
            <Box
              cursor="pointer"
              onClick={handleDelete}
              width="40px"
              height="40px"
              padding="10px"
              rounded="5px"
              as={FaTrashRestore}
              _hover={{ backgroundColor: 'gray.100' }}
            />

            {/* Bouton annulation delete */}
            <Box
              cursor="pointer"
              onClick={resetMode}
              width="40px"
              height="40px"
              padding="10px"
              rounded="5px"
              as={AiOutlineClose}
              _hover={{ backgroundColor: 'gray.100' }}
            />
          </>
        )}
      </Formiz>
      {!isReadOnly && (
        <ToolsButton
          height="100%"
          setEditMode={setEditMode}
          setDeleteMode={setDeleteMode}
          _hover={{ backgroundColor: 'gray.100' }}
        />
      )}
    </HStack>
  );
};

ShowFile.propTypes = {
  file: PropTypes.instanceOf(Object),
  isReadOnly: PropTypes.bool,
  invalidateQuery: PropTypes.func,
  isOnline: PropTypes.bool,
  params: PropTypes.string,
  callbackDelete: PropTypes.func,
};
ShowFile.defaultProps = {
  file: {},
  isReadOnly: true,
  invalidateQuery: () => null,
  callbackDelete: () => null,
  isOnline: true,
  params: '',
};
