import React from 'react';
import { Switch } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import { FormGroup } from 'components/form-group';

const propTypes = {
  textAlign: PropTypes.string,
  setCustomValue: PropTypes.func,
  ...fieldPropTypes,
};

const defaultProps = {
  textAlign: 'left',
  setCustomValue: () => {},
  ...fieldDefaultProps,
};

export const FieldSwitch = (props) => {
  const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(
    props
  );
  const {
    label,
    type,
    required,
    isDisabled,
    placeholder,
    textAlign,
    children,
    inputWidth,
    setCustomValue,
    ...otherProps
  } = props;

  const [isTouched, setIsTouched] = React.useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  const formGroupProps = {
    errorMessage,
    id,
    isRequired: !!required,
    label,
    showError,
    isDisabled,
    ...otherProps,
  };

  const handleChange = (e) => setValue(e.target.checked);

  return (
    <FormGroup {...formGroupProps}>
      <Switch
        onChange={(e) => {
          handleChange(e);
          setCustomValue(e);
        }}
        colorScheme="defaultTheme"
        size="md"
        onBlur={() => {
          setIsTouched(true);
        }}
        isDisabled={isDisabled}
        defaultChecked={value}
        aria-invalid={showError}
        aria-required={!!required}
        aria-describedby={showError ? `${id}-error` : null}
        id={`${id}`}
      />
    </FormGroup>
  );
};

FieldSwitch.propTypes = propTypes;
FieldSwitch.defaultProps = defaultProps;
