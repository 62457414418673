import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@chakra-ui/react';
import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import { FormGroup } from 'components';
import { FaCaretDown } from 'react-icons/fa';

const propTypes = {
  label: PropTypes.node,
  isDisabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.node,
      label: PropTypes.node,
    }),
  ),
  ...fieldPropTypes,
};

const defaultProps = {
  label: '',
  helper: '',
  isDisabled: false,
  options: [],
  ...fieldDefaultProps,
};

export const FieldSelectCustomHandleChange = (props) => {
  const {
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    errorMessage,
  } = useField(props);

  const {
    label, isDisabled, required, placeholder, handleCustomChange, options, setParentValue, ...otherProps
  } = props;

  const [isTouched, setIsTouched] = React.useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const handleBlur = () => {
    setIsTouched(true);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    handleCustomChange(e);
  };

  const formGroupProps = {
    errorMessage,
    id,
    isRequired: !!required,
    label,
    showError,
    isDisabled,
    ...otherProps,
  };

  return (
    <FormGroup {...formGroupProps}>

      <Select
        icon={<FaCaretDown />}
        iconColor="brandSecondary.500"
        id={id}
        key={resetKey}
        defaultValue={value}
        checked={value}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={placeholder}
        aria-invalid={showError}
        aria-describedby={!isValid ? `${id}-error` : null}
        disabled={isDisabled}
        colorScheme="brand"
      >
        {options?.length && options.map(({ value: v, label: l }) => (
          <option
            key={v}
            value={v}
          >
            {l}
          </option>
        ))}
      </Select>
    </FormGroup>
  );
};
FieldSelectCustomHandleChange.propTypes = propTypes;
FieldSelectCustomHandleChange.defaultProps = defaultProps;
