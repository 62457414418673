/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import { FormGroup } from 'components';
import { ReactSelectApps } from 'components/react-select/ReactSelectApps';

const propTypes = {
  label: PropTypes.node,
  isDisabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.node,
      label: PropTypes.node,
    })
  ),
  isMulti: PropTypes.bool,
  ...fieldPropTypes,
};

const defaultProps = {
  label: '',
  isMulti: false,
  isDisabled: false,
  options: [],
  ...fieldDefaultProps,
};

/**
 * Ce composant est une surcouche de FieldReactSelect dans composant mais il introduit la traduction
 *
 * @param {Object} props - Ma props
 *
 * @param {string} props.label - Label du composant
 *
 * @param {boolean} props.isDisabled - Si désactivé, on ne peut rien sélectionner
 *
 * @param {boolean|string} props.required - Si la props est mise, le composant ne doit pas être vide pour valider le formulaire.
 * Si un texte est mis, ce sera le texte qui sera affiché en-dessous du composant, en rouge
 *
 * @param {string} props.placeholder - Message affiché dans la sélection du select
 *
 * @param {array} props.options - Liste des options. Chacune des options doit avoir, au minimum, une clé value ainsi qu'une clé label {label: "label", value: "value"}
 *
 * @param {boolean} props.isMulti - Si true, possibilité de sélectionner plusieurs options
 *
 * @param {string} props.classNamePrefix - Message d'erreur
 */
export const FieldReactSelect = (props) => {
  const { t } = useTranslation();
  const {
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    errorMessage,
  } = useField(props);

  const {
    label,
    isDisabled,
    required,
    placeholder,
    options,
    isMulti,
    classNamePrefix,
    ...otherProps
  } = props;

  const [isTouched, setIsTouched] = React.useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const handleBlur = () => {
    setIsTouched(true);
  };

  const handleChange = (e) => {
    const filtered = Array.isArray(e) ? e?.map((el) => el.value) : e.value;
    setValue(filtered);
  };

  const formGroupProps = {
    errorMessage,
    id,
    isRequired: !!required,
    label,
    showError,
    isDisabled,
    ...otherProps,
  };

  let val = value;
  if (
    !Array.isArray(value) &&
    Object.prototype.toString.call(value) === '[object String]'
  ) {
    val = value?.split(',');
  }

  return (
    <FormGroup {...formGroupProps}>
      <ReactSelectApps
        id={id}
        key={resetKey}
        onChange={handleChange}
        defaultValue={
          Array.isArray(val)
            ? options?.filter((option) => val?.includes(option?.value))
            : ''
        }
        isMulti={isMulti}
        checked={value}
        onBlur={handleBlur}
        placeholder={placeholder}
        aria-invalid={showError}
        aria-required={!!required}
        aria-describedby={showError ? `${id}-error` : null}
        disabled={isDisabled}
        options={options.map((item) => ({
          value: t(`${item.value}`.toLowerCase(), { defaultValue: item.value }),
          label: t(`${item.label}`.toLowerCase(), {
            defaultValue: item.label,
          }),
        }))}
      />
    </FormGroup>
  );
};
FieldReactSelect.propTypes = propTypes;
FieldReactSelect.defaultProps = defaultProps;
