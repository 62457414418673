import React, { useState, useEffect } from 'react';
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { Navbar } from './navbar/Navbar';
import { PageContent } from './page-content';

export const LayoutDefault = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const isSmallDevice = useBreakpointValue({ base: true, md: false });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box h="100%" w="100%">
            <Flex flexDirection="row" w="100%" alignItems="stretch">
                <Navbar
                    isSmallDevice={isSmallDevice}
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                />
                <Flex w="100%" maxW="100%" flexDirection="column">
                    <PageContent mt={isSmallDevice ? '48px' : 0} minH="700px">
                        {children}
                    </PageContent>
                </Flex>
            </Flex>
        </Box>
    );
};

LayoutDefault.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node]),
};
LayoutDefault.defaultProps = {
    children: null,
};
