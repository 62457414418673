import { ModalHeader, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

export const ModalAppsHeader = (props) => {
  const { title, subTitle } = props;
  return (
    <ModalHeader>
      <Text fontWeight="bold" color="gray.700">
        {title}
      </Text>
      {subTitle && (
        <Text fontWeight="initial" color="gray.500" fontSize="sm">
          {subTitle}
        </Text>
      )}
    </ModalHeader>
  );
};

ModalAppsHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};
ModalAppsHeader.defaultProps = {
  title: 'Titre de la modal',
  subTitle: null,
};
