/* eslint-disable react/forbid-prop-types */
import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useStyleConfig } from '@chakra-ui/react';

export const ReactSelectApps = (props) => {
  const {
    options,
    onChange,
    placeholder,
    defaultValue,
    isMulti,
    closeMenuOnSelect,
    variant,
  } = props;

  const styles = useStyleConfig('ReactSelect', { variant });
  return (
    <Select
      menuPortalTarget={document.body}
      menuPosition="fixed"
      closeMenuOnSelect={closeMenuOnSelect}
      isMulti={isMulti}
      defaultValue={defaultValue}
      placeholder={placeholder}
      styles={styles}
      onChange={onChange}
      options={options}
      {...props}
    />
  );
};

ReactSelectApps.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Array),
  ]),
  isMulti: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  label: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  last: PropTypes.bool,
  variant: PropTypes.string,
};

ReactSelectApps.defaultProps = {
  options: [],
  placeholder: 'Débuter votre recherche',
  defaultValue: null,
  isMulti: false,
  closeMenuOnSelect: true,
  label: null,
  width: '350px',
  last: false,
  variant: 'default',
};
