/**
 * Variables communes
 */
export const APPENV = {
  APP_NAME: 'MolStrat',
  APP_CODE: 'APP_CODE',
  ENV: process.env.REACT_APP_ENV ?? '',
  HOST_API: process.env.REACT_APP_HOST_API ?? null,
  HOST_FRONT: process.env.REACT_APP_HOST_FRONT ?? null,
};
