import { Alert, AlertIcon, AlertDescription } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {Object} props
 * @param {string} props.message - Message d'info
 */
export const AlertInfo = (props) => {
  const { message } = props;
  return (
    <Alert borderRadius="10px" colorScheme="gray" status="info">
      <AlertIcon />
      <AlertDescription color="gray.700" display="block">
        {message}
      </AlertDescription>
    </Alert>
  );
};
AlertInfo.propTypes = {
  message: PropTypes.string.isRequired,
};
