import React from 'react';
import PropTypes from 'prop-types';
import { Divider as DividerChakra } from '@chakra-ui/react';

export const HDivider = ({ size, bgColor, ...rest }) => {
  const getStyles = () => {
    switch (size) {
      case '2xs':
        return { height: '0.5px' };
      case 'xs':
        return { height: '1px' };
      case 'sm':
        return { height: '2px' };
      case 'md':
        return { height: '4px' };
      case 'lg':
        return { height: '6px' };
      case 'xl':
        return { height: '8px' };
      default: {
        return { height: '0.5px' };
      }
    }
  };

  return (
    <DividerChakra
      borderColor="brand.500"
      textTransform="none"
      orientation="horizontal"
      backgroundColor={bgColor}
      {...getStyles()}
      {...rest}
    />
  );
};

HDivider.propTypes = {
  size: PropTypes.string,
  bgColor: PropTypes.string,
};

HDivider.defaultProps = {
  size: '2xs',
  bgColor: 'black',
};
