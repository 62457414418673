import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FooterLayout } from './footer-layout';

export const LayoutEmpty = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box m="auto" minW="400px" color="white" p={4}>
      {children}
      <Box minHeight="100px">
        <FooterLayout />
      </Box>
    </Box>
  );
};

LayoutEmpty.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node]),
};
LayoutEmpty.defaultProps = {
  children: null,
};
