import React from 'react';
import PropTypes from 'prop-types';
import { Divider as DividerChakra } from '@chakra-ui/react';

export const VDivider = ({ size, bgColor, ...rest }) => {
  const getStyles = () => {
    switch (size) {
      case '2xs':
        return { width: '0.5px' };
      case 'xs':
        return { width: '1px' };
      case 'sm':
        return { width: '2px' };
      case 'md':
        return { width: '4px' };
      case 'lg':
        return { width: '6px' };
      case 'xl':
        return { width: '8px' };
      default: {
        return { width: '0.5px' };
      }
    }
  };
  return (
    <DividerChakra
      textTransform="none"
      orientation="vertical"
      backgroundColor={bgColor}
      {...getStyles()}
      {...rest}
    />
  );
};

VDivider.propTypes = {
  size: PropTypes.string,
  bgColor: PropTypes.string,
};

VDivider.defaultProps = {
  size: '2xs',
  bgColor: 'black',
};
