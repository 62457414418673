import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { NoMatch } from 'pages/NoMatch';
import { PageSignIn } from 'pages/Login/signin';
import { PageAccueil } from 'pages/Accueil';
import { Authenticated } from 'Authenticated';
import { Profile } from 'pages/Profile';
import { PageCgu } from 'pages/Generalite';
import { PageSignOut } from 'pages/Login/signout';
import { PageProtectionDonnees } from 'pages/Generalite/PageProtectionDonnees';
import { FormulaireRoutes } from 'app/formulaire/FormulaireRoutes';

const LoginContainer = () => (
    <Switch>
        {/* Route application */}
        <Route exact path="/login" component={PageSignIn} />
        <Route exact path="/signout" component={PageSignOut} />
        <Route exact path="/" component={PageSignIn} />
        <Route path="*">
            <NoMatch />
        </Route>
    </Switch>
);

export const Routes = () => (
    <Switch>
        <Route exact path="/login" component={LoginContainer} />
        <Route exact path="/" component={LoginContainer} />
        <Route exact path="/signout" component={LoginContainer} />
        <Route exact path="/cgu" component={PageCgu} />
        <Route exact path="/protection" component={PageProtectionDonnees} />

        {/* Route application */}
        <Route
            exact
            path="/home"
            component={PageAccueil}
        />

        <Authenticated
            exact
            authorisedUsers={['all']}
            path="/formulaire"
            component={FormulaireRoutes}
        />
        {/* <Authenticated
      exact
      authorisedUsers={['all']}
      path="/home"
      component={PageAccueil}
    /> */}
        <Authenticated
            exact
            authorisedUsers={['all']}
            path="/profile"
            component={Profile}
        />

        <Route path="*">
            <NoMatch />
        </Route>
    </Switch>
);
