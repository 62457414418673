import React from 'react';

import { useAuthentificationContext } from 'context/auth-context';
import { Redirect } from 'react-router-dom';
import { LayoutLogin } from 'layout/LayoutLogin';
import { SignIn } from 'components/signin/SignIn';

export const PageSignIn = () => {
  const { initialisation, isLogged } = useAuthentificationContext();

  if (!initialisation && isLogged) {
    return <Redirect to="/home" />;
  }

  return (
    <LayoutLogin>
      <SignIn />
    </LayoutLogin>
  );
};
