import Axios from 'axios';
import { useQuery } from 'react-query';
import { APPENV } from 'config/config';

export const useGetFinalisation = (userId) =>
  useQuery(
    ['finalisation', { userId }],
    async () => {
      const result = await Axios.get(`${APPENV.HOST_API}/finalisation`);

      return result?.data;
    },
    { enabled: !!userId },
    []
  );
