import React from 'react';
import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import { FormGroup } from 'components';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import './date-picker/styles.css';
import {
  Input,
  Box,
  Icon,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import dateFnsFormat from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { isValid, parse } from 'date-fns';
import { formatDateDb } from 'functions/common-scope/utils';
import PropTypes from 'prop-types';
import MomentLocaleUtils from 'react-day-picker/moment';

import 'moment/locale/fr';

const propTypes = {
  format: PropTypes.string,
  ...fieldPropTypes,
};

const defaultProps = {
  format: 'dd/MM/yyyy',
  ...fieldDefaultProps,
};

const InputWithIcon = React.forwardRef((props, ref) => (
  <InputGroup ref={ref}>
    <Input {...props} />
    <InputRightElement pointerEvents="none">
      <Icon as={FaRegCalendarAlt} boxSize={6} />
    </InputRightElement>
  </InputGroup>
));

export const FieldDatePicker = (props) => {
  const {
    errorMessage,
    id,
    isValid: isValidate,
    isSubmitted,
    setValue,
    value,
  } = useField(props);

  const {
    label,
    type,
    required,
    isDisabled,
    placeholder,
    format,
    formatToSend,
    ...otherProps
  } = props;
  const [isTouched, setIsTouched] = React.useState(false);
  const showError = !isValidate && (isTouched || isSubmitted);
  const { t } = useTranslation();
  const lang = t('core.lang.shortcut', { defaultValue: 'fr' });

  const parseDate = (str, dateFormat, locale) => {
    const parsedDate = parse(str, dateFormat, new Date(), { locale });
    const isValidDate = isValid(parsedDate);
    if (isValidDate) {
      return parsedDate;
    }
    return undefined;
  };

  const onDayChange = (selectedDay) => {
    if (selectedDay !== undefined) {
      setValue(formatDateDb(selectedDay, formatToSend));
    } else {
      setValue(null);
    }
  };

  const formatDate = (date, dateFormat, locale) =>
    dateFnsFormat(date, dateFormat, locale);
  const formGroupProps = {
    errorMessage,
    id,
    isRequired: !!required,
    label,
    showError,
    isDisabled,
    ...otherProps,
  };
  return (
    <Box>
      <FormGroup {...formGroupProps}>
        <DayPickerInput
          id={id}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          value={
            value !== null && isValid(new Date(value)) ? new Date(value) : ''
          }
          onBlur={() => {
            setIsTouched(true);
          }}
          dayPickerProps={{
            localeUtils: MomentLocaleUtils,
            locale: lang,
          }}
          formatDate={formatDate}
          parseDate={parseDate}
          format={format}
          placeholder={`${dateFnsFormat(new Date(), format)}`}
          aria-invalid={showError}
          aria-required={!!required}
          aria-describedby={showError ? `${id}-error` : null}
          onDayChange={onDayChange}
          component={InputWithIcon ?? {}}
          style={{
            zIndex: 9999,
          }}
        />
      </FormGroup>
    </Box>
  );
};

FieldDatePicker.propTypes = propTypes;
FieldDatePicker.defaultProps = defaultProps;
