import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';

export const EtapeAction = ({ isActive, onClick, etape, ...rest }) => (
    <Stack
        justifyContent="center"
        alignItems="center"
        bg={isActive ? 'defaultTheme.500' : 'white'}
        color={isActive ? 'white' : 'black'}
        border="1px solid gray"
        minH="30px"
        // minW="30px"
        w="100%"
        borderRadius="10px"
        alignSelf="stretch"
        cursor="pointer"
        onClick={onClick}
        {...rest}
    >
        <Text p={isActive ? 4 : 0} as={isActive && 'b'}>{etape}</Text>
    </Stack>
)

EtapeAction.propTypes = {
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    etape: PropTypes.string.isRequired
}

EtapeAction.defaultProps = {
    isActive: false,
    onClick: undefined,
}
