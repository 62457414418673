import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { APPENV } from 'config/config';
import refbuild from 'config/build.json';

export const CheckNewRelease = () => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        // const clientRefBuild = window.localStorage.getItem('refbuild');
        window.localStorage.setItem('mustReloadApp', '0');
        const interval = setInterval(() => {
            const mustReloadApp = window.localStorage.getItem('mustReloadApp') ?? '0';
            // Deja eu l'avertissement
            if (mustReloadApp === '1') {
                return;
            }

            // Lecture de la conf
            axios
                .get(`${APPENV.HOST_FRONT}/build.json`)
                .then((response) => {
                    // Comparaison avec le localstorage
                    if (refbuild.refbuild !== response.refbuild) {
                        onOpen();
                        // console.log(`${refbuild.refbuild}!==${response.refbuild}`);
                        window.localStorage.setItem('mustReloadApp', '1');
                        // console.log('mustReloadApp = 1');
                    } else {
                        // console.log('build ok');
                    }
                })
                .catch(() => {
                    //   console.log('error loading build.json');
                });
        }, 60000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal onClose={onClose} size="md" isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader color="brand.500">
                    {t('Nouvelle version disponible')}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {t('Souhaitez-vous recharger la page ?')}
                </ModalBody>
                <ModalFooter>
                    <Button
                        backgroundColor="gray.300"
                        onClick={() => onClose()}
                        _hover={{
                            backgroundColor: 'gray.200',
                        }}
                    >
                        {t('Ignorer')}
                    </Button>
                    <Button
                        ml={2}
                        color="white"
                        backgroundColor="brand.500"
                        onClick={() => window.location.reload()}
                        _hover={{
                            backgroundColor: 'brand.400',
                        }}
                    >
                        {t('Rafraîchir')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
