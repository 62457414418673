import {

    Modal,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Stack,
    HStack,
    useDisclosure,
} from '@chakra-ui/react';
import { Formiz, useForm } from '@formiz/core';
import { useConfirmForm } from 'app/formulaire/services/finalisation/useConfirmForm';
import { useCreateFinalisation } from 'app/formulaire/services/finalisation/useCreateFinalisation';
import { useGetFinalisation } from 'app/formulaire/services/finalisation/useGetFinalisation';
import { useUpdateFinalisation } from 'app/formulaire/services/finalisation/useUpdateFinalisation';
import { ButtonApp } from 'components/button';
import { ButtonSubmit } from 'components/button/ButtonSubmit';
import { FieldInputTextArea } from 'components/fields/FieldInputTextArea';
import { FieldSwitch } from 'components/fields/FieldSwitch';
import { ModalAppsBody, ModalAppsFooter, ModalAppsHeader } from 'components/modal';
import { useAuthentificationContext } from 'context/auth-context';
import React from 'react';
import { SwitchEtape } from '../_partials/SwitchEtape';


export const TabFinalisation = () => {

    const form = useForm({ subscribe: 'form' });

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { user } = useAuthentificationContext();
    const { data, isLoading, isFetching } = useGetFinalisation(user?.utilisateurId);

    const successCallBack = () => {
        onClose();
    }
    const { mutate: mutationCreate, isLoading: isLoadingMutateCreate } = useCreateFinalisation({});
    const { mutate: mutationUpdate, isLoading: isLoadingMutateUpdate } = useUpdateFinalisation({ id: data?.id, userId: user?.utilisateurId });
    const { mutate: mutationConfirm, isLoading: isLoadingMutateConfirm } = useConfirmForm({ successCallBack });

    const handleSubmit = (values) => {
        if (data?.id !== undefined) {
            mutationUpdate(values);
        } else {
            mutationCreate(values);
        }
    };

    const handleConfirm = () => {
        mutationConfirm();
    }
    return (
        <>
            <Formiz autoForm connect={form} onValidSubmit={handleSubmit}>
                {!isLoading && !isFetching && (
                    <Stack spacing={4}>

                        <FieldInputTextArea defaultValue={data?.commentaire} name="commentaire" label="Dans le cadre de cette étude, souhaitez-vous aborder d'autres sujets ou thématiques ?" />
                        <FieldSwitch
                            defaultValue={data?.entretiensComplementaires}
                            name="entretiens_complementaires"
                            label="L'enquête est susceptible de faire l'objet d'entretiens complémentaires ultérieurement, accepteriez-vous de nous rencontrer dans le cadre de cette étude ?"
                        />

                        <Stack alignItems="flex-end">
                            {user?.canUpdate && (
                                <HStack>
                                    <ButtonSubmit
                                        label="Enregistrer"
                                        onClick={form.submit}
                                        iconOnly={false}
                                        disabled={!form.isValid}
                                        isLoading={isLoadingMutateCreate || isLoadingMutateUpdate || isLoadingMutateConfirm}
                                    />

                                    <ButtonApp
                                        label="Valider et envoyer"
                                        onClick={onOpen}
                                        iconOnly={false}
                                        disabled={!form.isValid}
                                        isLoading={isLoadingMutateCreate || isLoadingMutateUpdate || isLoadingMutateConfirm}
                                    />
                                </HStack>
                            )}
                            <SwitchEtape />
                        </Stack>
                    </Stack>
                )}
            </Formiz>

            <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalAppsHeader title="Validation du formulaire" />
                    <ModalCloseButton />
                    <ModalAppsBody>
                        En validant le formulaire, vous ne pourrez plus le modifier. Voulez-vous le valider ?
                    </ModalAppsBody>
                    <ModalAppsFooter
                        labelCancel="Annuler"
                        label="Créer"
                        onCancel={onClose}
                        onValid={() => handleConfirm()}
                    />
                </ModalContent>
            </Modal>
        </>
    );
};
