import React, { useState } from 'react';
import { Button, Stack } from '@chakra-ui/react';
import { Formiz, useForm } from '@formiz/core';
import { isEmail, isMinLength } from '@formiz/validations';
import { useTranslation } from 'react-i18next';
import { signInWithEmailPassword, signOut } from 'config/firebase';
import { FieldInput } from 'components';
import PropTypes from 'prop-types';
import { toastError } from 'functions/common-scope/toast';

export const FormLogin = ({ setIsReseting, setIsErrorLogin }) => {
    const { t } = useTranslation();
    const loginForm = useForm({
        subscribe: 'form',
    });
    const [isLoading, setIsLoading] = useState(false);


    const handleConnexion = (values) => {
        setIsLoading(true);

        signInWithEmailPassword(values.email, values.password?.trim())
            .then(() => { })
            .catch(() => {
                toastError({
                    title: t('app.components.signin.formlogin.error.login.title'),
                    description: t(
                        'app.components.signin.formlogin.error.login.description'
                    ),
                });
                signOut();
                setIsErrorLogin(true);
                setIsLoading(false);
            });
    };

    return (
        <>
            <Formiz connect={loginForm} onValidSubmit={handleConnexion}>
                <form noValidate onSubmit={loginForm.submit}>
                    <Stack spacing="6">
                        {/* Formulaire de login */}
                        <FieldInput
                            inputWidth="100%"
                            name="email"
                            label="Adresse mail"
                            required="Adresse mail obligatoire"
                            placeholder="adresse mail"
                            defaultValue=""
                            validations={[
                                {
                                    rule: isEmail(),
                                    message: "Email invalide",
                                },
                            ]}
                        />

                        {/* Formulaire du mot de passe  */}

                        <FieldInput
                            inputWidth="100%"
                            type="password"
                            name="password"
                            label="Mot de passe"
                            required="Mot de passe obligatoire"
                            validations={[
                                {
                                    rule: isMinLength(6),
                                    message: "Mot de passe de 6 caractères minimum",
                                },
                            ]}
                        />

                        <Button
                            isLoading={isLoading}
                            type="submit"
                            colorScheme="brand"
                            w="100%"
                        >
                            Se connecter
                        </Button>
                    </Stack>
                </form>
            </Formiz>
        </>
    );
};

FormLogin.propTypes = {
    setIsReseting: PropTypes.func.isRequired,
    setIsErrorLogin: PropTypes.func.isRequired,
};
