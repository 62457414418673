/**
 * Fichier pointant sur le firebase stating ou production
 * Le choix se fait dans le docker
 * Par d�faut c'est le contenu de test qui est pr�sent
 */

const { APPENV } = require('config/config');

let firebaseConfigEnv = {};
switch (APPENV.ENV) {
  default:
  case 'local':
  case 'staging':
  case 'demo':
    // conf local - staging
    firebaseConfigEnv = {
      apiKey: 'AIzaSyALPjGGi8PmS9AVrdC6i1n2kaXmkycNzqk',
      authDomain: 'total-inter-csms-staging.firebaseapp.com',
      databaseURL: 'https://total-inter-csms-staging.firebaseio.com',
      projectId: 'total-inter-csms-staging',
      storageBucket: 'total-inter-csms-staging.appspot.com',
      messagingSenderId: '254844517554',
      appId: '1:254844517554:web:c82727317dacc2f1f55dab',
    };
    break;

  case 'production':
    firebaseConfigEnv = {
      apiKey: 'AIzaSyDz6zKG-khci4LIod_dCN91RCvT0xRp7e4',
      authDomain: 'molstrat.firebaseapp.com',
      projectId: 'molstrat',
      storageBucket: 'molstrat.appspot.com',
      messagingSenderId: '564929519530',
      appId: '1:564929519530:web:3f287dac2046f8519155d3',
    };
    break;
}

export const firebaseConfig = firebaseConfigEnv;
