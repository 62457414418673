import React, { useEffect, useState } from 'react';
import { useAuthentificationContext } from 'context/auth-context';
import { Redirect } from 'react-router-dom';
import { LayoutLogin } from 'layout/LayoutLogin';
import { SignOut } from 'components/signin/SignOut';

export const PageSignOut = () => {
  const { logOut } = useAuthentificationContext();
  const [runRedirect, setRunRedirect] = useState(false);

  useEffect(() => {
    logOut();
    const timer = setTimeout(() => {
      setRunRedirect(true);
    }, 2000);
    return () => clearTimeout(timer);
  });

  if (runRedirect) {
    return <Redirect to="/login" />;
  }

  return (
    <LayoutLogin>
      <SignOut />
    </LayoutLogin>
  );
};
