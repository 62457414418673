import React from 'react';
import { useField } from '@formiz/core';
import {
  Box,
  IconButton,
  InputGroup,
  InputRightElement,
  Textarea,
} from '@chakra-ui/react';
import { FormGroup } from 'components';
import { FaSave } from 'react-icons/fa';
import PropTypes from 'prop-types';

/**
 * Composant select sous forme de menu déroulant ou de recherche suivant le nombre de maxOptions
 * @author Cavelier Mickael <mickael.cavelier@wyzengroup.com>
 * @param {Object} props
 *
 * @param {string} props.label - Label accompagnant le champ
 *
 * @param {bool} props.required - Indique si le champ est requis ou non
 *
 * @param {bool} props.isDisabled - Indique si le champ est désactivé ou non
 *
 * @param {string} props.placeholder - Valeur proposé à l'utilisateur dans le champ
 *
 * @param {string} props.textAlign - Alignement du texte dans le champ
 *
 * @param {bool} props.activeRightButton - Flag pour afficher le boutton
 *
 * @param {function} props.handleClickRightButton - Fonction executé au clic du bouton
 *
 * @param {string|number} props.customId - id custom
 *
 * @return
 */
export const FieldInputTextArea = (props) => {
  const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(
    props
  );
  const {
    label,
    required,
    isDisabled,
    placeholder,
    textAlign,
    activeRightButton,
    handleClickRightButton,
    customId,
    ...rest
  } = props;

  const [isTouched, setIsTouched] = React.useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  const formGroupProps = {
    errorMessage,
    id,
    isRequired: !!required,
    label,
    showError,
    isDisabled,
    ...rest,
  };

  return (
    <FormGroup h="100%" {...formGroupProps}>
      <InputGroup h="100%">
        <Textarea
          minH="80px"
          h="100%"
          textAlign={textAlign}
          id={id}
          type="textarea"
          value={value ?? ''}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => {
            setIsTouched(true);
          }}
          _hover={{
            background: 'white',
            borderColor: 'brandSecondary.500',
          }}
          focusBorderColor="brand.500"
          placeholder={placeholder}
          aria-invalid={showError}
          aria-required={!!required}
          aria-describedby={showError ? `${id}-error` : null}
        />

        {activeRightButton && (
          <InputRightElement width="4.5rem">
            <IconButton
              onClick={() => handleClickRightButton(customId, value)}
              icon={<Box as={FaSave} />}
              _active={{
                border: 'none',
              }}
              _hover={{
                color: 'green.200',
              }}
            />
          </InputRightElement>
        )}
      </InputGroup>
    </FormGroup>
  );
};

FieldInputTextArea.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  textAlign: PropTypes.string,
  activeRightButton: PropTypes.bool,
  handleClickRightButton: PropTypes.func,
  customId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FieldInputTextArea.defaultProps = {
  label: undefined,
  required: false,
  isDisabled: false,
  placeholder: 'Ecrivez-ici',
  textAlign: 'left',
  activeRightButton: false,
  handleClickRightButton: () => null,
  customId: undefined,
};
