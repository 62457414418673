import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonApp } from './ButtonApp';

export const ButtonRedirection = ({ to, label }) => (
  <ButtonApp as={Link} to={to} label={label} />
);

ButtonRedirection.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string,
};

ButtonRedirection.defaultProps = {
  label: 'app.components.buttons.default.redirect',
};
