import {
    Text,
    Stack,
    HStack,
} from '@chakra-ui/react';
import { Formiz, useForm } from '@formiz/core';
import { FieldInput, FieldSelect } from 'components';
import { FieldInputTextArea } from 'components/fields/FieldInputTextArea';
import { FormBox } from 'components/formulaire/FormBox';
import { SubGroup } from 'components/subgroup/SubGroup';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonDelete } from 'components/button';
import { isMinLength, isNumber } from '@formiz/validations';
import { ButtonSubmit } from 'components/button/ButtonSubmit';
import { useCreateProduitAjd } from 'app/formulaire/services/chimie-ajd/useCreateProduitAjd';
import { useUpdateProduitAjd } from 'app/formulaire/services/chimie-ajd/useUpdateProduitAjd';
import { useAuthentificationContext } from 'context/auth-context';
import { useDeleteProduitAjd } from 'app/formulaire/services/chimie-ajd/useDeleteProduitAjd';
import { FieldReactSelect } from 'components/fields/FieldReactSelect';
import { Countries } from 'app/formulaire/functions/Countries';



export const FormChimieAjd = ({ produit }) => {

    const { user } = useAuthentificationContext();

    const [typeZone, setTypeZone] = useState(produit?.zoneFabrication ?? undefined);
    const form = useForm({ subscribe: 'form' });
    const optionsUniteVolConsomme = [
        { value: 'tonnes', label: 'Tonnes' },
        { value: 'metre_cube', label: 'm³' },
    ];




    const optionsZone = [
        { value: 'asie', label: 'Asie' },
        { value: 'afrique', label: 'Afrique' },
        { value: 'europe', label: 'Europe' },
        { value: 'amerique_nord', label: 'Amérique du Nord' },
        { value: 'amerique_sud', label: 'Amérique du Sud' },
        { value: 'oceanie', label: 'Océanie' },
        { value: 'moyen_orient', label: 'Moyen-Orient' },
    ];


    const optionsModeApprovisionnement = [
        { value: 'fluvial', label: 'Maritime' },
        { value: 'maritime', label: 'Fluvial' },
        { value: 'fer', label: 'Fer' },
        { value: 'route', label: 'Route' },
    ];

    const optionsModeApprovisionnementSecondaire = [
        { value: 'fluvial', label: 'Maritime' },
        { value: 'maritime', label: 'Fluvial' },
        { value: 'fer', label: 'Fer' },
        { value: 'route', label: 'Route' },
        { value: 'pipe', label: 'Pipe' },
    ];

    const optionsConditionnement = [
        { value: 'vrac_liquide', label: 'Vrac liquide' },
        { value: 'vrac_sec', label: 'Vrac sec' },
        { value: 'conteneur_dry', label: 'Conteneur Dry' },
        { value: 'isotank', label: 'Isotank' },
        { value: 'groupage', label: 'Groupage' },
        { value: 'wagon', label: 'Wagon/UTI' },
    ];

    const optionsEvolutionConsommation = [
        { value: 'hausse', label: 'Hausse' },
        { value: 'baisse', label: 'Baisse' },
        { value: 'stabilite', label: 'Stabilité' },
    ];

    const optionsBool = [
        { label: 'Oui', value: 'true' },
        { label: 'Non', value: 'false' },
    ];

    const countries = Countries.getCountries();


    const { mutate: mutationCreate, isLoading: isLoadingMutateCreate } = useCreateProduitAjd({});
    const { mutate: mutationUpdate, isLoading: isLoadingMutateUpdate } = useUpdateProduitAjd({ id: produit?.id, userId: user?.utilisateurId });
    const { mutate: mutationDelete } = useDeleteProduitAjd({ id: produit?.id, userId: user?.utilisateurId });

    const removeProduit = () => {
        mutationDelete();
    };

    const handleSubmit = (values) => {
        let datas = {};
        if (values?.first_number_cas === null || values?.second_number_cas === null || values?.third_number_cas === null) {

            datas = { ...values };
        } else {
            const casNumber = `${values?.first_number_cas}-${values?.second_number_cas}-${values?.third_number_cas}`;
            datas = { ...values, casNumber };
        }

        if (produit?.id !== undefined) {
            mutationUpdate(datas);
        } else {
            mutationCreate(datas);
        }
    };


    return (
        <Formiz autoForm connect={form} onValidSubmit={handleSubmit}>
            <Stack spacing={4}>
                <Stack spacing={8}>
                    <FormBox
                        title="questionnaire:chapters.descriptionProduit"
                        actionHeader={
                            user?.canUpdate && (
                                <ButtonDelete
                                    label="Supprimer"
                                    action={() => removeProduit(produit)}
                                />
                            )}
                    >
                        <Stack spacing={4}>
                            <HStack>
                                <SubGroup title="Number CAS">
                                    <HStack alignItems="self-start">
                                        <FieldInput
                                            name="first_number_cas"
                                            maxLength={7}
                                            minLength={2}
                                            textAlign="right"
                                            w={{ base: "40%", md: "20%" }}
                                            numericOnly
                                            validations={[
                                                {
                                                    rule: isNumber(),
                                                    message: 'Doit etre un nombre',
                                                },
                                                {
                                                    rule: isMinLength(2),
                                                    message: 'Minimum 2 chiffres',
                                                },
                                            ]}
                                            defaultValue={produit?.casNumber?.split('-')[0]}
                                        />
                                        <Stack>
                                            <Text> - </Text>
                                        </Stack>
                                        <FieldInput
                                            name="second_number_cas"
                                            maxLength={2}
                                            minLength={2}
                                            textAlign="right"
                                            w={{ base: "20%", md: "13%" }}
                                            numericOnly
                                            validations={[
                                                {
                                                    rule: isNumber(),
                                                    message: 'Doit etre un nombre',
                                                },
                                                {
                                                    rule: isMinLength(2),
                                                    message: 'Minimum 2 chiffres',
                                                },
                                            ]}
                                            defaultValue={produit?.casNumber?.split('-')[1]}

                                        />
                                        <Text> - </Text>
                                        <FieldInput
                                            name="third_number_cas"
                                            maxLength={1}
                                            minLength={1}
                                            textAlign="right"
                                            w={{ base: '15%', md: "8%" }}
                                            numericOnly
                                            validations={[
                                                {
                                                    rule: isNumber(),
                                                    message: 'Doit etre un nombre',
                                                },
                                                {
                                                    rule: isMinLength(1),
                                                    message: 'Minimum 1 chiffre',
                                                },
                                            ]}
                                            defaultValue={produit?.casNumber?.split('-')[2]}

                                        />
                                    </HStack>
                                </SubGroup>
                            </HStack>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldInput name="nom_technique" label="Nom technique" defaultValue={produit?.nomTechnique} />
                            </Stack>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldInput name="purete" label="Pureté (%)" numericOnly withPercentage defaultValue={produit?.purete} />
                                <FieldInput name="concentration" label="Concentration (%)" numericOnly withPercentage defaultValue={produit?.concentration} />
                            </Stack>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldInput name="volume_consomme" label="Volumes consommés/an" numericOnly defaultValue={produit?.volumeConsommeAnnuel} />
                                <FieldSelect
                                    name="unite_volume"
                                    label="Unité du vol. conso. (tonnes ou m3)"
                                    options={optionsUniteVolConsomme}
                                    defaultValue={produit?.volumeConsommeUnite}
                                />
                            </Stack >
                            <SubGroup title="Zone de fabrication">
                                <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                    <FieldSelect name="zone" label="Zone" options={optionsZone} setParentValue={setTypeZone} defaultValue={typeZone} />
                                    <FieldReactSelect name="pays" label="Pays" options={countries} defaultValue={produit?.paysFabrication} />
                                    <FieldInput name="ville" label="Ville" defaultValue={produit?.villeFabrication} />
                                </Stack>
                            </SubGroup>
                        </Stack>
                    </FormBox>
                    <FormBox title="questionnaire:chapters.approvisionnement">
                        <Stack spacing={4}>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }} w={{ base: '100%', md: '50%' }}>
                                <FieldSelect
                                    name="mode_approvisionnement"
                                    label="Mode d'approvisionnement principal (arrivée en Europe/France)"
                                    options={optionsModeApprovisionnement}
                                    defaultValue={produit?.modeApprovisionnementPrincipal}
                                />
                            </Stack>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldReactSelect name="pays_entree" label="Pays d'entrée en Europe" options={countries} defaultValue={produit?.paysEntreeEurope} />
                                <FieldInput name="ville_entree" label="Ville d'entrée en Europe" defaultValue={produit?.villeEntreeEurope} />

                            </Stack>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldSelect
                                    name="mode_approvisionnement_secondaire"
                                    label="Mode d'approvisionnement secondaire (arrivée sur site)"
                                    options={optionsModeApprovisionnementSecondaire}
                                    defaultValue={produit?.modeApprovisionnementSecondaire}
                                />
                                <FieldSelect
                                    name="conditionnement"
                                    label="Conditionnement (Déf. Groupage : regroupement dans un seul chargement de produits appartenant à plusieurs clients)"
                                    options={optionsConditionnement}
                                    defaultValue={produit?.conditionnement}
                                />
                            </Stack>
                        </Stack>
                    </FormBox>

                    <FormBox title="questionnaire:chapters.evolution">
                        <Stack spacing={4}>
                            <HStack>
                                <FieldSelect
                                    name="evolution_consommation"
                                    label="Evolution de la consommation à 5 ans"
                                    options={optionsEvolutionConsommation}
                                    defaultValue={produit?.evolutionConsommation}
                                />
                            </HStack>
                            <SubGroup title="Evolution du sourcing souhaitée à 5 ans">
                                <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                    <FieldSelect
                                        name="evolution_zone"
                                        label="Zone"
                                        options={optionsZone}
                                        defaultValue={produit?.zoneEvolutionSourcing}
                                    />
                                    <FieldReactSelect name="evolution_pays" label="Pays" options={countries} defaultValue={produit?.paysEvolutionSourcing} />
                                </Stack>
                            </SubGroup>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }} w={{ base: '100%', md: '50%' }}>
                                <FieldSelect
                                    name="produit_vert"
                                    options={optionsBool}
                                    label={`Recherchez-vous pour ce produit une fabrication d'origine "verte / décarbonée" ?`}
                                    defaultValue={produit?.fabricationVerte}
                                />
                            </Stack>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldInputTextArea
                                    name="produit_vert_commentaire"
                                    label="Commentaire"
                                    defaultValue={produit?.fabricationVerteCommentaire}
                                />
                            </Stack>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldSelect
                                    name="mutualisation"
                                    label="Seriez-vous ouvert à une mutualisation de votre approvisionnement sur ce produit ?"
                                    defaultValue={produit?.mutualisationApprovisionnement}
                                    options={optionsBool}
                                />
                                <FieldSelect
                                    name="fournisseur_local"
                                    label="Envisageriez-vous de vous fournir localement (Normandie) ?"
                                    options={optionsBool}
                                    defaultValue={produit?.approvisionnementLocal}
                                />
                            </Stack>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldInputTextArea
                                    name="contraintes_mutualisation"
                                    label="Quelles seraient les contraintes pour la mise en place de cette mutualisation ?"
                                    defaultValue={produit?.contraintesMutualisation}
                                />
                                <FieldInputTextArea
                                    name="contraintes_fournisseur_local"
                                    label="Quelles sont les contraintes pour vous approvisionner en local ?"
                                    defaultValue={produit?.contraintesApprovisionnement}
                                />
                            </Stack>
                        </Stack>
                    </FormBox>
                </Stack>
                {user?.canUpdate && (
                    <Stack alignItems="flex-end">
                        <ButtonSubmit
                            label="Enregistrer"
                            onClick={form.submit}
                            iconOnly={false}
                            isLoading={isLoadingMutateCreate || isLoadingMutateUpdate}
                            disabled={!form.isValid}
                        />
                    </Stack>
                )}
            </Stack>
        </Formiz>
    );
};

FormChimieAjd.propTypes = {
    produit: PropTypes.instanceOf(Object),
};

FormChimieAjd.defaultProps = {
    produit: undefined,
};
