import React from 'react';
import PropTypes from 'prop-types';
import { BsFillTrash2Fill } from 'react-icons/bs';
import { ButtonApp } from './ButtonApp';

export const ButtonIcon = ({
  onClick,
  width,
  height,
  icon,
  cursor,
  ...rest
}) => (
  <ButtonApp
    onClick={onClick}
    cursor={cursor}
    w={width}
    h={height}
    as={icon}
    {...rest}
  />
);

ButtonIcon.propTypes = {
  onClick: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  icon: PropTypes.instanceOf(Object),
  size: PropTypes.string,
  cursor: PropTypes.string,
};

ButtonIcon.defaultProps = {
  onClick: () => null,
  width: '30px',
  height: '30px',
  icon: BsFillTrash2Fill,
  size: 'xs',
  cursor: 'pointer',
};
