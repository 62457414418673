import React, { useState } from 'react';
import { BsFillTrash2Fill } from 'react-icons/bs';
import { AiOutlineCheck } from 'react-icons/ai';
import { ImCross } from 'react-icons/im';
import { HStack, Tooltip } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components';

export const ButtonDeleteInline = ({
  handleConfirm,
  labelDelete,
  labelConfirm,
  labelCancel,
  ...rest
}) => {
  const { t } = useTranslation();
  const [confirm, setConfirm] = useState(false);
  return (
    <HStack>
      {/* Bouton de suppression */}
      {!confirm && (
        <Tooltip
          label={t(labelDelete)}
          placement="bottom"
          backgroundColor="red.500"
          color="white"
        >
          <span>
            <Icon
              onClick={() => setConfirm(true)}
              cursor="pointer"
              backgroundColor="red.500"
              as={BsFillTrash2Fill}
              _hover={{
                backgroundColor: 'red.600',
              }}
              color="white"
              {...rest}
            />
          </span>
        </Tooltip>
      )}
      {/* Bouton d'annulation */}
      {confirm && (
        <>
          <Tooltip
            label={t(labelCancel)}
            placement="bottom"
            backgroundColor="red.500"
            color="white"
          >
            <span>
              <Icon
                onClick={() => setConfirm(false)}
                cursor="pointer"
                backgroundColor="red.500"
                color="white"
                as={ImCross}
                _hover={{
                  backgroundColor: 'red.600',
                }}
                {...rest}
              />
            </span>
          </Tooltip>

          <Tooltip
            label={t(labelConfirm)}
            placement="bottom"
            backgroundColor="green.500"
            color="white"
          >
            <span>
              <Icon
                onClick={handleConfirm}
                cursor="pointer"
                backgroundColor="green.500"
                color="white"
                as={AiOutlineCheck}
                _hover={{
                  backgroundColor: 'green.600',
                }}
                {...rest}
              />
            </span>
          </Tooltip>
        </>
      )}
    </HStack>
  );
};

ButtonDeleteInline.propTypes = {
  handleConfirm: PropTypes.func,
  labelDelete: PropTypes.string,
  labelConfirm: PropTypes.string,
  labelCancel: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

ButtonDeleteInline.defaultProps = {
  handleConfirm: () => null,
  labelDelete: 'app.components.buttons.default.delete',
  labelConfirm: 'app.components.buttons.default.confirm',
  labelCancel: 'app.components.buttons.default.cancel',
  width: '30px',
  height: '30px',
};
