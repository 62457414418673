import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

export const Icon = ({ icon: IconEl, size, color, ...props }) => (
  <Box
    as="span"
    d="inline-block"
    position="relative"
    w="1em"
    flex="none"
    _before={{
      content: '"."',
      visibility: 'hidden',
    }}
    color={color}
    {...props}
  >
    <Box
      as={IconEl}
      size={size}
      position="absolute"
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
    />
  </Box>
);

Icon.propTypes = {
  icon: PropTypes.func,
  size: PropTypes.string,
  color: PropTypes.string,
};

Icon.defaultProps = {
  icon: () => null,
  size: '1em',
  color: 'gray.800',
};
