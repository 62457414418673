import { Text } from '@chakra-ui/react';
import { Titre } from 'components/heading/Titre';
import { LayoutEmpty } from 'layout/LayoutEmpty';
import React from 'react';

export const NoMatch = () => (
  <LayoutEmpty>
    <Titre color="orange">
      <Text>Page introuvable</Text>
    </Titre>
  </LayoutEmpty>
  );
