import { extendTheme, theme } from '@chakra-ui/react';
import tailwindColors from './tailwindColors';

// import 'typeface-roboto';

import Input from './components/input';
import Select from './components/select';
import Textarea from './components/textarea';
import Divider from './components/divider';
import { TableApps } from './components/tableApps';
import { FormLayout } from './components/formLayout';
import { ReactSelect } from './components/reactSelect';

const fonts = {
  body: 'Roboto, sans-serif',
  heading: 'Roboto, sans-serif',
  mono: 'Menlo, monospace',
};

const fontSizes = {
  '2xs': '10px',
};

const borderRadius = {
  default: '10px',
  small: '5px',
};

const colors = {
  // Go to https://smart-swatch.netlify.com/ to easily generate a new color palette.
  lightWhite: '#F7FAFB',
  white: '#ffffff',
  blue: {
    50: '#e0f4ff',
    100: '#b8dcfa',
    200: '#8ec4f1',
    300: '#63ace8',
    400: '#3994e0',
    500: '#1f7bc6',
    600: '#135f9b',
    700: '#084470',
    800: '#002946',
    900: '#000f1d',
  },

  gray: tailwindColors.coolGray,

  success: {
    50: '#e6fae8',
    100: '#c6e9c8',
    200: '#a4d9a6',
    300: '#82c984',
    400: '#5fb963',
    500: '#46a049',
    600: '#357c38',
    700: '#255927',
    800: '#133615',
    900: '#001400',
  },

  warning: {
    50: '#fff2de',
    100: '#fbd7b6',
    200: '#f3b88b',
    300: '#ed975f',
    400: '#e67433',
    500: '#cc6619',
    600: '#a05812',
    700: '#73460c',
    800: '#462f03',
    900: '#1e1100',
  },

  danger: {
    50: '#ffe3e8',
    100: '#feb6be',
    200: '#ffd07d',
    300: '#f25969',
    400: '#ee2b3e',
    500: '#d41125',
    600: '#a60b1c',
    700: '#770613',
    800: '#4a0109',
    900: '#1f0001',
  },

  brand: {
    50: '#e3f3ff',
    100: '#bdd9f5',
    200: '#96bee9',
    300: '#6ea4dd',
    400: '#478ad2',
    500: '#2d71b8',
    600: '#205890',
    700: '#143f68',
    800: '#062641',
    900: '#000e1b',
  },
  brandSecondary: {
    50: '#e2fced',
    100: '#c0efd2',
    200: '#9be2b6',
    300: '#75d79a',
    400: '#4fcb7f',
    500: '#36b265',
    600: '#288a4e',
    700: '#1b6337',
    800: '#0b3c1f',
    900: '#001605',
  },

  defaultTheme: {
    50: '#e3f3ff',
    100: '#bdd9f5',
    200: '#96bee9',
    300: '#6ea4dd',
    400: '#478ad2',
    500: '#2d71b8',
    600: '#205890',
    700: '#143f68',
    800: '#062641',
    900: '#000e1b',
  },

  form: {
    border: '2px solid #E2E4E7',
  },
};

const shadows = {
  card: '0 4px 8px rgba(0, 0, 0, 0.1)',
};

const sizes = {
  ...theme.sizes,
  circularProgress: {
    xs: '1rem',
    sm: '1.5rem',
    md: '2rem',
    lg: '2.5rem',
    xl: '3rem',
    '2xl': '3.5rem',
  },
};

const styles = {
  global: {
    'html, body': {
      bg: 'gray.100',
    },
  },
};

export const customTheme = extendTheme({
  components: {
    Input,
    Select,
    Textarea,
    Divider,
    TableApps,
    FormLayout,
    ReactSelect,
  },
  styles,
  sizes,
  fonts,
  fontSizes,
  borderRadius,
  colors,
  shadows,
  menuWidth: {
    base: '10rem',
    xs: '5rem',
  },
  headerHeight: 16,
});
