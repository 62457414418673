import { Box, Stack, Image, Flex } from '@chakra-ui/react';
import { Titre } from 'components/heading/Titre';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Logo from "assets/logo.png";

export const FormLayout = ({ children, ...rest }) => {
    const { t } = useTranslation();
    return (
        <Flex w="100%" h="100vh" spacing={0} direction={{ base: 'column', lg: 'row' }} alignItems="stretch">
            <Box backgroundColor="defaultTheme.700" w="100%" h="100%" display="flex" justifyContent="center" alignItems="center">
                <Image h="50%" w="50%" src={Logo} />
            </Box>
            <Box
                p={8}
                color="gray.500"
                boxShadow="card"
                borderRadius="md"
                fontWeight="semibold"
                letterSpacing="wide"
                fontSize="xs"
                backgroundColor="white"
                display="flex"
                alignItems="center"
                justifyContent="center"
                w="100%"
                h="100%"
                {...rest}
            >
                <Stack spacing={6} w={{ base: "100%", lg: "50%" }} boxShadow="card" p={8} borderRadius={8}>
                    <Titre color="brand.600" size="h4" fontWeight="bold">
                        {t('MolStrat')}
                    </Titre>
                    <Box>{children}</Box>
                </Stack>
            </Box>
        </Flex>
    );
};

FormLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node]),
};
FormLayout.defaultProps = {
    children: null,
};
