import React, { useEffect } from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const LayoutLogin = ({ children }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Grid height="100vh" templateRows="1fr 100px">
            <GridItem alignSelf="center" alignItems="center" alignContent="center">
                {children}
            </GridItem>

        </Grid>
    );
};

LayoutLogin.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node]),
};
LayoutLogin.defaultProps = {
    children: null,
};
