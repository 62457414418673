import React, { useEffect, useState } from 'react';
import Uppy from '@uppy/core';
import UppyImageCompressor from 'uppy-plugin-image-compressor';
import { DragDrop, StatusBar } from '@uppy/react';
import { Box, Stack, ListIcon, ListItem, List } from '@chakra-ui/react';
import { FormGroup, ShowFiles } from 'components';
import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import XHRUpload from '@uppy/xhr-upload';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getCurrentUser } from 'config/firebase';
import { APPENV } from 'config/config';

const propTypes = {
  ...fieldPropTypes,
};

const defaultProps = {
  ...fieldDefaultProps,
};

export const FieldUppy = (props) => {
  const { errorMessage, id, isValid, isSubmitted, value, setValue } = useField(
    props
  );

  const { t } = useTranslation();
  const [errorUpload, setErrorUpload] = useState([]);

  const {
    initFiles,
    perimetre,
    customFileName,
    isAllowedUpload,
    url,
    label,
    required,
    isReadOnly,
    invalidateQuery,
    setIsLoading,
    isDisabled,
    ...rest
  } = props;

  const [savedFiles, setSavedFiles] = useState(initFiles);
  const Metas =
    customFileName === undefined
      ? { perimetre }
      : { perimetre, customFileName };

  const [token, setToken] = useState('');

  const getToken = async () => {
    const jwt = await getCurrentUser().getIdToken(false);
    setToken(jwt);
  };

  useEffect(() => {
    // You need to restrict it at some point
    // This is just dummy code and should be replaced by actual
    if (!token) {
      getToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Do all the configuration here
  const uppy = React.useMemo(
    () =>
      Uppy({ autoProceed: true })
        .use(XHRUpload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          formData: true,
          endpoint: url,
          method: 'post',
        })
        .use(UppyImageCompressor, {
          size: 2, // the max size in MB, defaults to 2MB
          quality: 0.6, // the quality of the image, max is 1,
          maxWidth: 1920, // the max width of the output image, defaults to 1920px
          maxHeight: 1920, // the max height of the output image, defaults to 1920px
          resize: true,
        }),
    [url, token]
  );

  uppy.setMeta({ Metas });
  uppy.on('upload', () => {
    if (setIsLoading !== undefined) setIsLoading(true);
  });

  uppy.on('complete', (result) => {
    // Cas du fail
    if (result.failed.length !== 0) {
      result.failed.map((x, index) =>
        setErrorUpload({
          key: index,
          message: t(x.response.body.error.description),
        })
      );
    }
    // Cas du succès
    if (result.successful.length !== 0) {
      const fileRes = result.successful.map((x) => {
        const name = x.name.split('.').slice(0, -1).join('.');
        return {
          id: x.response.body.data.hashFileName,
          fileName: `${customFileName || name}.${x.extension}`,
          originalName: `${name}.${x.extension}`,
          hash: x.response.body.data.hashFileName,
        };
      });
      if (value !== null) {
        setValue(value.concat(fileRes));
      } else {
        setValue(fileRes);
      }
      setSavedFiles(savedFiles.concat(fileRes));
      if (setIsLoading !== undefined) setIsLoading(false);
    }
  });

  React.useEffect(
    () =>
      function cleanup() {
        uppy.close();
      },
    [uppy]
  );

  const callbackDelete = (fichierId) => {
    // Uppy
    const fileToRemove = uppy
      .getFiles()
      .filter((file) => file.response.body.data.hashFileName === fichierId);
    if (fileToRemove.length > 0) {
      uppy.removeFile(fileToRemove[0].id);
    }
    // Init Files + nouveaux fichiers
    setSavedFiles(savedFiles.filter((file) => file.id !== fichierId));

    // Nouveaux fichiers
    if (value !== null) {
      setValue(value.filter((file) => file.hash !== fichierId));
    }
  };

  const showError = !isValid && isSubmitted;
  const formGroupProps = {
    errorMessage,
    id,
    isRequired: !!required,
    label,
    showError,
    isDisabled,
    ...rest,
  };

  return (
    <FormGroup {...formGroupProps}>
      <Stack>
        {isAllowedUpload && (
          <>
            <Box>
              <DragDrop
                height="8rem"
                uppy={uppy}
                locale={{
                  strings: {
                    dropHereOr: t('uppy.dragndrop', {
                      defaultValue: 'Drop here or %{browse}',
                    }),
                    browse: 'browse',
                  },
                }}
              />
            </Box>

            <StatusBar
              uppy={uppy}
              hideUploadButton
              hideAfterFinish={false}
              showProgressDetails
            />
            {errorUpload.length !== 0 && (
              <>
                {([errorUpload] || []).map((file) => (
                  <List key={file.key}>
                    <ListItem key={file.key}>
                      <ListIcon icon="not-allowed" color="red.500" />
                      {file.message}
                    </ListItem>
                  </List>
                ))}
              </>
            )}
            {savedFiles?.length !== 0 && savedFiles && (
              <ShowFiles
                callbackDelete={callbackDelete}
                invalidateQuery={invalidateQuery}
                files={savedFiles}
                isReadOnly={isReadOnly}
              />
            )}
          </>
        )}
      </Stack>
    </FormGroup>
  );
};

FieldUppy.propTypes = {
  ...propTypes,
  initFiles: PropTypes.instanceOf(Array),
  perimetre: PropTypes.string,
  customFileName: PropTypes.string,
  isAllowedUpload: PropTypes.bool,
  url: PropTypes.string,
  isReadOnly: PropTypes.bool,
  invalidateQuery: PropTypes.func,
};

FieldUppy.defaultProps = {
  ...defaultProps,
  initFiles: [],
  perimetre: '',
  customFileName: '',
  isAllowedUpload: true,
  url: `${APPENV.HOST_API}/file/upload/temporary`,
  isReadOnly: false,
  invalidateQuery: () => null,
};
