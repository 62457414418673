/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
import Axios from 'axios';
import { getCurrentUser } from './firebase';

Axios.interceptors.response.use(
  (response) => response?.data,
  (error) => {
    if (error?.response?.request.response) {
      const errorOjb = JSON.parse(error?.response?.request.response);
      // Si l'utilisateur est enlev� de Firebase il y a redirection login et fin de la session
      if (errorOjb.statusCode === 401) {
        if (errorOjb.error.type === 'UnAuthorizedException') {
          window.location.href = '/unauthorized';
        }
      }
    }
    return Promise.reject(error);
  }
);

Axios.interceptors.request.use(
  async (config) => {
    const currentUser = getCurrentUser();
    if (currentUser !== null) {
      const value = await currentUser.getIdToken(false);
      config.headers = {
        Authorization: `Bearer ${value}`,
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);
