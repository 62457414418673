import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react';
import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import { FormGroup } from 'components/form-group';

const propTypes = {
  label: PropTypes.node,
  isDisabled: PropTypes.bool,
  colorScheme: PropTypes.string,
  ...fieldPropTypes,
};

const defaultProps = {
  label: '',
  colorScheme: 'brand',
  isDisabled: false,
  ...fieldDefaultProps,
};

export const FieldCheckbox = (props) => {
  const {
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    errorMessage,
  } = useField(props);

  const { label, isDisabled, required, colorScheme, ...rest } = props;

  const [isTouched, setIsTouched] = React.useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const handleBlur = () => {
    setIsTouched(true);
  };

  const handleChange = (e) => setValue(e.target.checked);

  const formGroupProps = {
    errorMessage,
    id,
    isRequired: !!required,
    showError,
    isDisabled,
    ...rest,
  };

  return (
    <FormGroup {...formGroupProps}>
      <CheckboxGroup>
        <Checkbox
          id={id}
          key={resetKey}
          defaultChecked={value}
          onBlur={handleBlur}
          onChange={handleChange}
          aria-invalid={showError}
          aria-describedby={!isValid ? `${id}-error` : null}
          isDisabled={isDisabled}
          colorScheme={colorScheme}
        >
          {label}
        </Checkbox>
      </CheckboxGroup>
    </FormGroup>
  );
};
FieldCheckbox.propTypes = propTypes;
FieldCheckbox.defaultProps = defaultProps;
