import { Button, IconButton } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const ButtonApp = (props) => {
    const { t } = useTranslation();
    const { label, iconOnly, leftIcon, ...rest } = props;
    if (iconOnly) {
        return <IconButton icon={leftIcon} colorScheme="defaultTheme" {...rest} />;
    }
    return (
        <Button leftIcon={leftIcon} {...rest}>
            {t(label)}
        </Button>
    );
};

ButtonApp.propTypes = {
    label: PropTypes.string,
    iconOnly: PropTypes.bool,
    leftIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.instanceOf(Object)]),
};

ButtonApp.defaultProps = {
    label: 'create.default',
    iconOnly: false,
    leftIcon: null,
};
