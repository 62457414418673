/* eslint-disable react/forbid-prop-types */
import React from 'react';
import {
  Box,
  Container,
  Flex,
  HStack,
  Image,
  SimpleGrid,
  Stack,
  Text,
  Link,
} from '@chakra-ui/react';
import { VDivider } from 'components';
import PropTypes from 'prop-types';


const ListHeader = ({ children }) => (
  <Text fontWeight="700" fontSize="lg" mb={2}>
    {children}
  </Text>
);

ListHeader.propTypes = {
  children: PropTypes.string,
};
ListHeader.defaultProps = {
  children: '',
};

export const FooterLayout = ({ ...rest }) => (
  <Box backgroundColor="white" color="darkgray" {...rest}>
    <Container as={Stack} maxW="6xl" py={10}>
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={8}>
        <HStack align="flex-start">
          <VDivider />
          <Stack align="flex-start">
            <ListHeader>Application</ListHeader>
            <Link href="/home">Accueil</Link>
            <Link href="/profile">Mon compte</Link>
            <Link href="/configuration">Configuration</Link>
          </Stack>
        </HStack>
        <HStack align="flex-start">
          <VDivider />
          <Stack>
            <ListHeader color="black">Support</ListHeader>
          </Stack>
        </HStack>

        <HStack align="flex-start">
          <VDivider />
          <Stack>
            <ListHeader color="black">Legal</ListHeader>
            <Link href="/protection">Protection des données personnelles</Link>
            <Link href="/cgu"> C.G.U.</Link>
          </Stack>
        </HStack>
      </SimpleGrid>
    </Container>
    <Box py={10}>
      <Flex
        align="center"
        _before={{
          content: '""',
          borderBottom: '1px solid',
          borderColor: 'darkgray',
          flexGrow: 1,
          mr: 8,
        }}
        _after={{
          content: '""',
          borderBottom: '1px solid',
          borderColor: 'darkgray',
          flexGrow: 1,
          ml: 8,
        }}
      >
        <Image boxShadow shadow="md" width="100px" src="/media/logo.png" />
      </Flex>
      <Text pt={6} fontSize="sm" textAlign="center" />
    </Box>
  </Box>
);

FooterLayout.propTypes = {};
