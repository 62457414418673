import React, { useState, useEffect, useCallback } from 'react';
import { Stack } from '@chakra-ui/react';
import { useEtapeContext } from 'app/formulaire/context/EtapeProvider';
import { useForm } from '@formiz/core';
import PropTypes from 'prop-types';
import { useAuthentificationContext } from 'context/auth-context';
import { ButtonApp } from 'components/button';
import { HiArrowNarrowLeft, HiArrowNarrowRight } from 'react-icons/hi';

export const SwitchEtape = (props) => {

    const { isLoading, isFetching, children } = props;
    const { user } = useAuthentificationContext();
    const form = useForm();
    const { etape, setEtape } = useEtapeContext();
    const [etapePrecedenteLibelle, setEtapePrecedenteLibelle] = useState();
    const [etapeSuivanteLibelle, setEtapeSuivanteLibelle] = useState();

    const handleButtons = () => {
        if (etape === 1) {
            return "end";
        }
        if (etape === 5) {
            return "start";
        }
        return "space-between";
    }

    const handleButtonsPrecedent = useCallback(() => {
        switch (etape) {
            case 2:
                return { label: "Identification", bg: 'gray.200', color: 'black' };
            case 3:
                return { label: "Chimie actuelle", bg: "defaultTheme.500", color: "white" };
            case 4:
                return { label: "Chimie de demain", bg: "green.500", color: "white" };
            case 5:
                return { label: "Valorisation", bg: "purple.500", color: "white" };
            default:
                return "";
        }
    }, [etape]);

    const handleButtonsSuivant = useCallback(() => {
        switch (etape) {
            case 1:
                return { label: "Chimie actuelle", bg: "defaultTheme.500", color: "white" };
            case 2:
                return { label: "Chimie de demain", bg: "green.500", color: "white" };
            case 3:
                return { label: "Valorisation", bg: "purple.500", color: "white" };
            case 4:
                return { label: "Validation", bg: "gray.200", color: "black" };
            default:
                return "";
        }
    }, [etape]);

    useEffect(() => {
        setEtapePrecedenteLibelle(handleButtonsPrecedent());
        setEtapeSuivanteLibelle(handleButtonsSuivant());
    }, [handleButtonsPrecedent, handleButtonsSuivant]);


    const handleEtape = (value) => {
        if (form.isValid && !form.isPristine && user?.canUpdate) {
            form.submit();
        }
        setEtape((old) => old + value);
    }


    return (
        <Stack direction={{ base: 'column', md: 'row' }} w="100%" justifyContent={handleButtons}>
            {etape > 1 && (
                <ButtonApp onClick={() => handleEtape(-1)} isLoading={isLoading || isFetching} {...etapePrecedenteLibelle} leftIcon={<HiArrowNarrowLeft />} />

            )}
            {children}
            {etape < 5 && (
                <ButtonApp onClick={() => handleEtape(+1)} isLoading={isLoading || isFetching} {...etapeSuivanteLibelle} rightIcon={<HiArrowNarrowRight />} />

            )}

        </Stack>
    )
}


SwitchEtape.propTypes = {
    isLoading: PropTypes.bool,
    isFetching: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Array)]),
}

SwitchEtape.defaultProps = {
    isLoading: false,
    isFetching: false,
    children: null,
}
