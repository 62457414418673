import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@chakra-ui/react';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.node]),
  noHeader: PropTypes.bool,
};

const defaultProps = {
  noHeader: false,
  children: null,
};

export const PageContent = ({ children, noHeader, ...rest }) => (
  <Flex
    p={4}
    w="100%"
    direction="column"
    flexGrow="1"
    {...rest}
  >
    {children}
  </Flex>
);

PageContent.propTypes = propTypes;
PageContent.defaultProps = defaultProps;
