// Les propriétés des selects
export const ReactSelect = {
  baseStyle: {
   
  input: (styles) => ({
    ...styles,
  }),
  singleValue: (styles) => ({
    ...styles,

  }),
  control: (styles) => ({
    ...styles,
  }),
  placeholder: (styles) => ({
    ...styles,
  }),
  option: (styles) => ({
    ...styles,
    ':hover': {
      backgroundColor: '#f3f4f6',
    },
  }),
  // Les tags des valeurs sélectionnées
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: '#37ae8f',
    color: 'white',
    ':hover': {
      backgroundColor: '#288868',
    },
  }),
  // Le label des tags des valeurs sélectionnées
  multiValueLabel: (styles) => ({
    ...styles,
    color: 'white',
  }),
  // La div pour remove un des tags des valeurs sélectionnées
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'white',
    ':hover': {
      backgroundColor: '#75d4bf',
      color: 'white',
    },
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 99,
  }),
},
defaultProps: {
  variant: 'default',
},
};
