import React, { useState } from 'react';
import { Button, Stack, Text } from '@chakra-ui/react';
import { Formiz, useForm } from '@formiz/core';
import { isEmail } from '@formiz/validations';
import { useTranslation } from 'react-i18next';
import { FieldInput } from 'components';
import { sendPasswordResetEmail } from 'config/firebase';
import PropTypes from 'prop-types';
import { toastError, toastSuccess } from 'functions/common-scope/toast';

export const FormReseting = ({ setIsReseting, setIsErrorLogin }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const loginForm = useForm({
    subscribe: 'form',
  });

  const handleBack = () => {
    setIsReseting(false);
  };

  const handleResetPassword = (values) => {
    setIsLoading(true);
    sendPasswordResetEmail(values.login)
      .then(async () => {
        toastSuccess({
          title: t(
            'app.components.signin.formseting.success.motdepasseenvoye.title'
          ),
          description: t(
            'app.components.signin.formseting.success.motdepasseenvoye.description'
          ),
        });
        setIsReseting(false);
        setIsLoading(false);
      })
      .catch(() => {
        setIsErrorLogin(true);
        setIsReseting(false);
        setIsLoading(false);
        toastError({
          title: t(
            'app.components.signin.formseting.error.motdepasseenvoye.title'
          ),
          description: t(
            'app.components.signin.formseting.error.motdepasseenvoye.description'
          ),
        });
      });
  };

  return (
    <>
      <Formiz connect={loginForm} onValidSubmit={handleResetPassword}>
        <form noValidate onSubmit={loginForm.submit}>
          <Stack spacing="6">
            {/* Formulaire de login */}
            <FieldInput
              inputWidth="100%"
              name="login"
              type="email"
              required={t('app.core.form.required.email')}
              label={t('app.core.form.label.email')}
              validations={[
                {
                  rule: isEmail(),
                  message: t('app.core.form.validation.email'),
                },
              ]}
            />
            <Button
              isLoading={isLoading}
              type="submit"
              colorScheme="brand"
              w="100%"
            >
              {t('app.components.signin.formreseting.reset')}
            </Button>
          </Stack>
        </form>
      </Formiz>
      <Text
        as="button"
        my={2}
        color="gray.900"
        textAlign="left"
        fontSize="0.8rem"
        fontStyle="italic"
        textDecoration="underline"
        onClick={handleBack}
        w="100%"
      >
        {t('app.components.signin.formreseting.signin')}
      </Text>
    </>
  );
};

FormReseting.propTypes = {
  setIsReseting: PropTypes.func.isRequired,
  setIsErrorLogin: PropTypes.func.isRequired,
};
