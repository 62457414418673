import React from 'react';
import { Flex, List, IconButton } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FaTimes, FaUser, FaBars } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { HiHome, HiLogout } from 'react-icons/hi';
import { RiSurveyLine } from 'react-icons/ri';
import { NavItem } from './NavItem';

export const NavbarDefault = ({
    isSidebarOpen,
    setIsSidebarOpen,
    isSmallDevice,
    ...props
}) => (
    <>
        <Flex
            alignItems="center"
            bg="defaultTheme.700"
            width={isSmallDevice ? '100%' : '7rem'}
            position={isSmallDevice ? 'fixed' : 'relative'}
            ml={!isSidebarOpen && isSmallDevice && '-100%'}
            color="lightWhite"
            direction="column"
            justifyContent="space-between"
            transition="all 0.2s ease"
            zIndex="100"
            {...props}
        >
            {isSidebarOpen && isSmallDevice && (
                <IconButton
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    position="absolute"
                    zIndex="100"
                    top="1rem"
                    right="1rem"
                    bg="brand.700"
                    icon={<FaTimes />}
                />
            )}
            <List
                w="100%"
                textAlign="center"
                position="sticky"
                top="0"
                display="flex"
                height="100vh"
                flexDirection="column"
            >
                <NavItem
                    as={HiHome}
                    to="/home"
                    label="Accueil"
                />
                <NavItem
                    as={RiSurveyLine}
                    to="/formulaire"
                    label="Formulaire"
                />
                {!isSmallDevice && (
                    <>
                        <NavItem
                            marginTop="auto"
                            to="/signout"
                            as={HiLogout}
                            label="Se déconnecter"
                        />
                    </>
                )}
            </List>
        </Flex>

        {isSmallDevice && (
            <Flex
                h="4rem"
                bg="white"
                alignItems="center"
                position="fixed"
                justifyContent="space-between"
                w="100%"
                zIndex="99"
            >
                <IconButton
                    colorScheme="brand"
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    aria-label="Menu"
                    icon={<FaBars />}
                    ml={4}
                />
                <IconButton
                    as={Link}
                    to="/profile"
                    colorScheme="brand"
                    aria-label="Menu"
                    icon={<FaUser />}
                    mr={4}
                />
            </Flex>
        )}
    </>
);

NavbarDefault.propTypes = {
    isSidebarOpen: PropTypes.bool,
    setIsSidebarOpen: PropTypes.func,
    isSmallDevice: PropTypes.bool,
};

NavbarDefault.defaultProps = {
    isSidebarOpen: false,
    setIsSidebarOpen: () => { },
    isSmallDevice: false,
};
