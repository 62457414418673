import React from 'react';
import { useFlexLayout, useResizeColumns, useTable } from 'react-table';
import PropTypes from 'prop-types';
import { Stack, useStyleConfig } from '@chakra-ui/react';
import { ButtonApp } from 'components/button';

export const Table = ({
  columns,
  data,
  variant,
  canAdd,
  onClickAdd,
  labelAdd,
}) => {
  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 0, // minWidth is only used as a limit for resizing
      width: 15, // width is used for both the flex-basis and flex-grow
      maxWidth: 300, // maxWidth is only used as a limit for resizing
    }),
    []
  );
  const {
    getTableProps,

    getTableBodyProps,

    headerGroups,

    footerGroups,

    rows,

    prepareRow,
  } = useTable(
    { columns, data, initialState: { hiddenColumns: ['id'] }, defaultColumn },
    useFlexLayout,
    useResizeColumns
  );

  const styles = useStyleConfig('TableApps', { variant });
  return (
    <Stack w="100%" spacing={0}>
      <table
        {...getTableProps()}
        style={{ ...getTableProps().style, ...styles?.table }}
      >
        <thead style={styles?.head ?? {}}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    style={{ ...cell.getCellProps().style, ...styles?.td }}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
        <tfoot style={styles?.footer}>
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <td {...column.getFooterProps()}>{column.render('Footer')}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      {canAdd && (
        <ButtonApp
          label={labelAdd}
          onClick={onClickAdd}
          borderTopRadius={0}
          backgroundColor="defaultTheme.500"
          borderBottomRadius={8}
          {...styles?.btnAdd}
        />
      )}
    </Stack>
  );
};

Table.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Array),
  ]),
  columns: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Array),
  ]),
  variant: PropTypes.string,
  canAdd: PropTypes.bool,
  labelAdd: PropTypes.string,
  onClickAdd: PropTypes.func,
};

Table.defaultProps = {
  data: {},
  columns: {},
  variant: 'default',
  canAdd: false,
  labelAdd: 'Ajouter',
  onClickAdd: () => {},
};
