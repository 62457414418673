import React from 'react';
import { useEtapeContext } from 'app/formulaire/context/EtapeProvider';
import { TabChimieAjd } from '../chimieAjd/TabChimieAjd';
import { TabChimieDemain } from '../chimieDemain/TabChimieDemain';
import { TabValorisation } from '../valorisation/TabValorisation';
import { TabFinalisation } from '../finalisation/TabFinalisation';
import { TabIdentification } from '../identification/TabIdentification';

export const CurrentFormController = () => {
    const { etape } = useEtapeContext();

    switch (etape) {
        case 1:
            return <TabIdentification />;
        case 2:
            return <TabChimieAjd />
        case 3:
            return <TabChimieDemain />;
        case 4:
            return <TabValorisation />;
        case 5:
            return <TabFinalisation />;
        default:
            return <TabIdentification />;
    }
}
