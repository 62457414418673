import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@chakra-ui/react';

export const ContentLayout = ({ children, ...rest }) => (
  <Flex
    minHeight="100vh"
    width="100%"
    position="relative"
    paddingX={{
      base: '10px',
      lg: '30px',
      xl: '70px',
    }}
    paddingY="15px"
    direction="column"
    {...rest}
  >
    {children}
  </Flex>
);

ContentLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node]),
};

ContentLayout.defaultProps = {
  children: null,
};
