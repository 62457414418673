/* eslint-disable no-unused-vars */
import React, {
    useState,
    createContext,
    useEffect,
    useContext,
    useCallback,
} from 'react';
import { auth } from 'config/firebase';
import Axios from 'axios';
import { APPENV } from 'config/config';
import { toastError } from 'functions/common-scope/toast';

export const AuthentificationContext = createContext();
export const useAuthentificationContext = () =>
    useContext(AuthentificationContext);
export const AuthentificationProvider = (props) => {
    // Set an initializing state whilst Firebase connects

    const [initializing, setInitializing] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [user, setUser] = useState(null);
    const logOut = () => {
        setUser(null);
        auth.signOut();
    };

    const login = useCallback(() => {
        const unsubscribe = auth.onAuthStateChanged(async (userAuth) => {
            setIsLoading(true);
            if (userAuth) {
                await Axios.get(`${APPENV.HOST_API}/check-auth`)
                    .then((response) => {
                        setUser(response?.data);
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        toastError({
                            title: 'Compte bloqué',
                            description: 'Votre compte a été bloqué',
                        });
                        if (error?.response?.request.response) {
                            const errorOjb = JSON.parse(error?.response?.request.response);
                            if (errorOjb.statusCode === 401 || errorOjb.statusCode === 403) {
                                setUser(null);
                            }
                        }
                        setIsLoading(false);
                    });
            } else {
                setUser(null);
                setIsLoading(false);
            }
            setInitializing(false);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        login();
    }, [login]);

    const isLogged = user !== null;

    const isAdmin = user?.isAdmin;

    return (
        <AuthentificationContext.Provider
            value={{
                logOut,
                user,
                setUser,
                isLoading,
                initializing,
                setInitializing,
                isLogged,
                isAdmin
            }}
            {...props}
        />
    );
};
