import React, { useState } from 'react';
import {
    Box,
    Flex,
    Stack,
    useBreakpointValue,
    useStyleConfig,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { Navbar } from './navbar/Navbar';
import { HeaderLayout } from './header-layout';

export const LayoutForm = ({ children, ...props }) => {
    const { to, cursor, title, ...rest } = props;

    const styles = useStyleConfig('FormLayout', { rest });

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const isSmallDevice = useBreakpointValue({ base: true, lg: false });

    return (
        <Box h="100%" w="100%">
            <Flex flexDirection="row" w="100%" alignItems="stretch">
                <Navbar
                    isSmallDevice={isSmallDevice}
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                />
                <Stack w="100%" spacing={10}>
                    <HeaderLayout to={to} cursor={cursor} title={title} />
                    <Stack
                        w="100%"
                        mt={isSmallDevice ? '48px' : 0}
                        minH="500px"
                        justifyContent="space-evenly"
                        flexDirection={isSmallDevice ? 'column' : 'row'}
                    >
                        <Stack
                            width={isSmallDevice ? '100%' : '80%'}
                            order={isSmallDevice ? 2 : 1}
                            flexDirection="column"
                            __css={styles.mainContent}
                        >
                            {children}
                        </Stack>
                    </Stack>
                </Stack>
            </Flex>
        </Box>
    );
};

LayoutForm.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node]),
    variant: PropTypes.string,
    title: PropTypes.string.isRequired,
    to: PropTypes.string,
    cursor: PropTypes.string,
};
LayoutForm.defaultProps = {
    children: null,
    variant: 'dft',
    cursor: '',
    to: '',
};
