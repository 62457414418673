import React from 'react';
import {
  Flex,
  Box,
  Tooltip,
  Badge,
  ListItem,
} from '@chakra-ui/react';
import {
  Link, useRouteMatch,
} from 'react-router-dom';
import PropTypes from 'prop-types';

export const NavItem = ({
    to, label, marginTop, ...rest
   }) => {
   const isActive = !!useRouteMatch(to);

   return (
     <ListItem
       marginTop={marginTop}
       padding="1rem"
       borderRight={isActive ? 'solid 1px' : 'none'}
       borderRightColor={isActive ? 'brand.700' : 'none'}
       color={isActive ? 'brand.700' : 'brandSecondary.500'}
       bg={isActive ? 'white' : 'brand.700'}
       _hover={{
         color: isActive ? 'black' : 'brand.200',
       }}
       focus={{
         color: isActive ? 'black' : 'brand.200',
       }}
     >
       <Tooltip
         label={label}
         placement="right"
         backgroundColor="gray.700"
         color={isActive ? 'brand.700' : 'brandSecondary.500'}
       >
         <Link to={to}>
           <Flex
             flexDirection="column"
             alignItems="center"
           >
             <Box
               cursor="pointer"
               color={isActive ? 'brand.700' : 'white'}
               padding="10px"
               borderRadius="5px"
               width="50px"
               height="50px"
               _hover={{
                    color: 'brand.200',
                }}
               {...rest}
             />
             {/* Badge pour différencier les uploads */}
             <Badge fontSize="8px" backgroundColor="brand.700" color="white">
               {label}
             </Badge>
           </Flex>
         </Link>
       </Tooltip>
     </ListItem>
     );
};

   NavItem.propTypes = {
     to: PropTypes.string,
     label: PropTypes.string.isRequired,
     marginTop: PropTypes.string,
   };

   NavItem.defaultProps = {
     to: '#',
     marginTop: '',

   };
