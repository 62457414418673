import { ModalBody } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

export const ModalAppsBody = (props) => {
  const { children } = props;
  return <ModalBody>{children}</ModalBody>;
};

ModalAppsBody.propTypes = {
  children: PropTypes.node,
};

ModalAppsBody.defaultProps = {
  children: <>Corps de la modal</>,
};
