import { ModalFooter, HStack } from '@chakra-ui/react';
import { ButtonCancel, ButtonCreation } from 'components/button';
import PropTypes from 'prop-types';
import React from 'react';

export const ModalAppsFooter = (props) => {
    const { onCancel, label, labelCancel, onValid, isLoading, isDisabled } = props;
    return (
        <ModalFooter backgroundColor="gray.50">
            <HStack>
                <ButtonCancel label={labelCancel} onClick={onCancel} />
                <ButtonCreation
                    isLoading={isLoading}
                    color="white"
                    onClick={onValid}
                    as="button"
                    isDisabled={isDisabled}
                    label={label}
                />
            </HStack>
        </ModalFooter>
    );
};

ModalAppsFooter.propTypes = {
    onCancel: PropTypes.func,
    label: PropTypes.string,
    labelCancel: PropTypes.string,
    onValid: PropTypes.func,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
};

ModalAppsFooter.defaultProps = {
    onCancel: () => null,
    label: 'valider',
    labelCancel: 'annuler',
    onValid: () => null,
    isLoading: false,
    isDisabled: false,
};
