import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Text } from '@chakra-ui/react';

export const FormCheckBox = (props) => {
  const {
    label,
    onChange,
    mb,
  } = props;

  return (
    <Checkbox
      mb={mb}
      onChange={onChange}
    >
      <Text
        color="gray.600"
        fontSize="sm"
        fontWeight="bold"
      >
        {label}
      </Text>
    </Checkbox>
  );
};

FormCheckBox.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  mb: PropTypes.string,
};

FormCheckBox.defaultProps = {
  mb: '15px',
};
