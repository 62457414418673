import {
    Stack,
} from '@chakra-ui/react';
import { Formiz, useForm } from '@formiz/core';
import { useCreateIdentification } from 'app/formulaire/services/identification/useCreateIdentification';
import { useGetIdentification } from 'app/formulaire/services/identification/useGetIdentification';
import { useUpdateIdentification } from 'app/formulaire/services/identification/useUpdateIdentification';
import { FormBox } from 'components/formulaire/FormBox';
import { useAuthentificationContext } from 'context/auth-context';
import React from 'react';
import { SwitchEtape } from '../_partials/SwitchEtape';
import { FormIdentification } from './_partials/FormIdentification';


export const TabIdentification = () => {

    const form = useForm({ subscribe: 'form' });
    const { user } = useAuthentificationContext();

    const { data, isLoading, isFetching } = useGetIdentification(user?.utilisateurId);

    const { mutate: mutationCreate, isLoading: isLoadingMutateCreate } = useCreateIdentification({});
    const { mutate: mutationUpdate, isLoading: isLoadingMutateUpdate } = useUpdateIdentification({ id: data?.id, userId: user?.utilisateurId });
    const handleSubmit = (values) => {
        if (data?.id !== undefined) {
            mutationUpdate(values);
        } else {
            mutationCreate(values);
        }
    };

    return (
        <FormBox title="questionnaire:chapters.identification">
            <Formiz autoForm connect={form} onValidSubmit={handleSubmit}>
                <Stack spacing={8}>
                    {!isLoading && !isFetching && (
                        <FormIdentification identification={data} />
                    )}
                    <Stack alignItems="flex-end">
                        <SwitchEtape isLoading={isLoadingMutateCreate || isLoadingMutateUpdate} />
                    </Stack>
                </Stack>
            </Formiz>
        </FormBox>

    );
};
