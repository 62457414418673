import { createStandaloneToast } from '@chakra-ui/react';
import { customTheme } from 'theme/customTheme';

/**
 * Custom Toast (success, error, warning, info)
 * Usage: toastSuccess({props})
 * props: les props du toast
 */

/**
 * Valeurs par défaut
 */
const defaultParamsToast = {
    status: 'success',
    position: 'top-right',
    duration: 9000,
    isClosable: true,
};

export const toastSuccess = (props) => {
    const toast = createStandaloneToast({ theme: customTheme });
    return toast({ ...defaultParamsToast, ...props });
};

export const toastError = (props) => {
    const toast = createStandaloneToast({ theme: customTheme });
    return toast({ ...defaultParamsToast, status: 'error', ...props });
};

export const toastInfo = (props) => {
    const toast = createStandaloneToast({ theme: customTheme });
    return toast({
        ...defaultParamsToast, position: 'top', status: 'info', ...props,
    });
};

export const toastWarning = (props) => {
    const toast = createStandaloneToast({ theme: customTheme });
    return toast({ ...defaultParamsToast, status: 'warning', ...props });
};
