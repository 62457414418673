import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@chakra-ui/react';

export const Timeline = ({ spacing, children, direction, ...rest }) => (
    <Stack spacing={spacing} direction={direction} {...rest}>
        {children}
    </Stack>
);

Timeline.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    direction: PropTypes.oneOfType(PropTypes.string, PropTypes.instanceOf(Object))
};

Timeline.defaultProps = {
    children: null,
    spacing: 8,
    direction: "vertical"
};
