import Axios from 'axios';
import { APPENV } from 'config/config';
import { getCurrentUser } from 'config/firebase';

export const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};

// returns a string on success, null in failure
const fileNameFromUrl = (url) => {
  const matches = url.match(/\/([^/?#]+)[^/]*$/);
  if (matches.length > 1) {
    return matches[1];
  }
  return null;
};

export const getFileUrlById = (fileId) =>
  // console.log("call getFileUrlById")
  fileId !== undefined ? `${APPENV.HOST_API}/file/${fileId}` : null;

export const getToken = async () =>
  //  return  getCurrentUser().getIdToken(false) firebase exemple
  setTimeout(
    () => 'token', // count is 0 here
    3000
  );

/**
 * Téléchargement de fichier depuis appel API
 * @param {string} url
 * @param {(function|null)} callback
 * @param {(boolean)} external true si pas d'envoi de token
 */
export const handleDownload = async ({
  url: urlDownload,
  callback,
  external = false,
}) => {
  let addHeaders = {};
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

  if (external === false) {
    const value = await getCurrentUser().getIdToken(false);
    console.log('toto');
    addHeaders = {
      Authorization: `Bearer ${value}`,
    };
  }

  let fileName = '';
  await fetch(urlDownload, {
    headers: {
      ...addHeaders, // Authorization: `Bearer ${value}`,
    },
    responseType: 'arraybuffer',
  })
    .then(handleErrors)
    .then((response) => {
      if (!response.ok || response.status !== 200) {
        throw new Error(response.status);
      }
      const contentDisposition =
        response.headers.get('content-disposition') ?? response.url;
      const matches = filenameRegex.exec(contentDisposition);
      // Si pas de filename dans le content-disposition, on prend l'url
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      } else {
        fileName = fileNameFromUrl(response.url);
      }
      return response.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      if (callback !== undefined) {
        callback();
      }
    })
    .catch((error) => error);
};

export const handleDownloadById = (fileId) => {
  // const jwtToken = getCurrentUser().getIdToken(false);
  const url = `${APPENV.HOST_API}/file/${fileId}`;
  return handleDownload({ url });
};

export const removeFile = (id, isTemp) => {
  const result = Axios.delete(`${APPENV.HOST_API}/document/${id}`, {
    data: { isTemp },
  });
  return result;
};
