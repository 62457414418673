import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import dayjs from 'dayjs';
import * as locales from '../locales';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // initImmediate: true,
    // whitelist: ['fr', 'en'],
    // nonExplicitWhitelist: false,
    // defaultNS: 'translation',
    // fallbackNS: 'translation',
    defaultNS: 'common',
    ns: Object.keys(locales.fr),
    resources: locales,
    lng: 'fr',
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {
    // Set current language, for locale translations in dayjs
    dayjs.locale(i18n.language);
  });

export const switchLanguage = () => {
  const newLanguage = i18n.language === 'en' ? 'fr' : 'en';
  i18n.changeLanguage(newLanguage);
  dayjs.locale(newLanguage);
};

export const updateLanguage = (newLanguage) => {
  i18n.changeLanguage(newLanguage);
  dayjs.locale(newLanguage);
};
