import Axios from 'axios';
import { APPENV } from 'config/config';
import { useAuthentificationContext } from 'context/auth-context';
import { toastError, toastSuccess } from 'functions/common-scope/toast';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

export const useConfirmForm = (props) => {
  const { errorCallBack, successCallBack, userId } = {
    ...props,
  };
  const { t } = useTranslation();
  const { setUser } = useAuthentificationContext();

  return useMutation(() => Axios.post(`${APPENV.HOST_API}/valider`), {
    onError: () => {
      toastError({
        description: t('Erreur pour valider le formulaire'),
      });
      return errorCallBack === undefined ? '' : errorCallBack();
    },
    onSuccess: () => {
      toastSuccess({
        description:
          "Merci d'avoir participé à cette enquête. France Chimie et HAROPA PORT s'engagent à garantir la confidentialité des informations que vous avez renseignées.",
      });

      setUser((old) => ({ ...old, canUpdate: false }));

      return successCallBack === undefined ? '' : successCallBack();
    },
  });
};
