import React from 'react';
import PropTypes from 'prop-types';
import { ButtonApp } from './ButtonApp';

export const ButtonCancel = (props) => {
  const { label, isLoading, onClick, ...rest } = props;
  return (
    <ButtonApp
      backgroundColor="white"
      border="2px"
      borderColor="gray.200"
      color="gray.700"
      fontWeight="bold"
      isLoading={isLoading}
      label={label}
      colorScheme="brand"
      onClick={onClick}
      _hover={{ backgroundColor: 'gray.300' }}
      {...rest}
    />
  );
};

ButtonCancel.propTypes = {
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

ButtonCancel.defaultProps = {
  isLoading: false,
  label: 'app.components.buttons.default.cancel',
  onClick: () => null,
};
