import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ButtonApp } from './ButtonApp';

export const ButtonCreation = (props) => {
  const {
    url,
    label,
    display,
    isLoading,
    backgroundColor,
    color,
    as,
    ...rest
  } = props;
  return (
    <>
      {display && (
        <ButtonApp
          backgroundColor={backgroundColor}
          isLoading={isLoading}
          label={label}
          color={color}
          as={as}
          to={url}
          _hover={{ backgroundColor: 'defaultTheme.700' }}
          {...rest}
        />
      )}
      {!display && <></>}
    </>
  );
};

ButtonCreation.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  display: PropTypes.bool,
  isLoading: PropTypes.bool,
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.instanceOf(Object),
  ]),
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
};

ButtonCreation.defaultProps = {
  url: '/',
  label: 'app.components.buttons.default.create',
  display: true,
  isLoading: false,
  as: Link,
  backgroundColor: 'defaultTheme.500',
  color: 'white',
};
