import React, { useCallback, useState } from 'react';
import { HStack, Stack, Text } from '@chakra-ui/react';
import { LayoutDefault } from 'layout/LayoutDefault';
import { Card, CardHeader, CardTitle } from 'components';
import { ButtonRedirection } from 'components/button';
import { useAuthentificationContext } from 'context/auth-context';
import { handleDownload } from 'functions/common-scope/files';
import { APPENV } from 'config/config';
import { toastError } from 'functions/common-scope/toast';
import { useTranslation } from 'react-i18next';
import { ButtonExtract } from 'components/button/ButtonExtract';

export const PageAccueil = () => {
    const { user, isAdmin, isLoading, isLogged } = useAuthentificationContext();

    const { t } = useTranslation();
    const [isLoadingExtract, setIsLoadingExtract] = useState(false);

    // Extract du pdml
    const handleExtract = useCallback(async () => {
        try {
            // Start loading extract
            setIsLoadingExtract(true);
            await handleDownload({ url: `${APPENV.HOST_API}/export-excel`, type: 'xlsx' });
        } catch (error) {
            toastError({
                title: t("app:core.errors.extract_title"),
                description: t("app:core.errors.description"),
            });
        }
        setIsLoadingExtract(false);
    }, [t]);
    return (
        <LayoutDefault>
            <Card p={4}>
                <CardHeader>
                    <CardTitle>Étude "Molécules stratégiques Axe Seine"</CardTitle>
                </CardHeader>
                <Stack spacing={4}>
                    <Text>
                        Dans le cadre de la réalisation de cette étude sur les molécules stratégiques pour l’Axe Seine, nous vous sollicitons afin de répondre au questionnaire ci-dessous. Cette enquête, réalisée auprès de 150 industriels normands, nous permettra d’identifier les molécules stratégiques pour l’attractivité de la Vallée de la Seine et le développement de l'industrie le long de l’Axe Seine. L'objectif est de disposer des informations utiles pour décider des actions à mettre en place pour (re)localiser en Normandie la fabrication de produits essentiels à l'autonomie et à l'avenir de notre industrie régionale.
                        Ces données pourront également permettre d'aider les entreprises à optimiser leurs schémas d’approvisionnement et éventuellement de développer des synergies entre acteurs régionaux, notamment en matière de décarbonation ou d'économie circulaire.
                    </Text>
                    <Text as="b">
                        IMPORTANT : Les données collectées seront traitées comme étant strictement confidentielles et pour les seuls besoins de notre étude. Les données de votre entreprise ne feront en aucun cas l'objet d'un traitement individualisé ni d'une communication vers des tiers. Les résultats de notre étude seront consolidés et anonymisés. Nous nous engageons par ailleurs à assurer le meilleur niveau de protection des données qui nous sont confiées.
                    </Text>
                    {!user?.canUpdate && !isLoading && isLogged && (
                        <Text as="b" color="red.500">Le questionnaire a déjà été validé. Par conséquent, vous ne pouvez y accéder qu'en lecture seule.</Text>
                    )}
                    <HStack justifyContent="flex-end">

                        <ButtonRedirection to="/formulaire" label="Accéder au formulaire" />
                        {isAdmin && (

                            <ButtonExtract
                                onClick={handleExtract}
                                isLoading={isLoadingExtract}
                            />
                        )}
                    </HStack>
                </Stack>
            </Card>
        </LayoutDefault>
    );
};
