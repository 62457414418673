import React from 'react';
import {
    Box,
    Text,
    Textarea,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';

/**
 * @author Cavelier Mickael
 */
export const FormTextArea = (props) => {
    const borderColorGray = 'gray.200';
    const {
        value,
        setValue,
        isValid,
        errorMessage,
        isSubmitted,
    } = useField(props);

    const showError = (!isValid && isSubmitted);

    const {
        placeholder,
        borderColor = borderColorGray,
        focusBorderColor = borderColorGray,
        name,
        defaultValue,
        w,
        isDisabled,
        label,
        mb,
    } = props;

    return (
      <Box
        mb={mb}
        width={w}
      >
        <Box>

          {/* Libell� */}
          {
                    label && (
                    <Box
                      mb={2}
                    >

                      <Text
                        color="gray.500"
                      >
                        {label}
                      </Text>
                    </Box>
                    )
                }

          {/* Champ de formulaire */}
          <Textarea
            backgroundColor="white"
            border="1px"
            isDisabled={isDisabled}
            id={name}
            type="textarea"
            value={value || defaultValue || ''}
            borderColor={(isSubmitted && !isValid && 'red.600') || borderColor || borderColorGray}
            onChange={(e) => setValue(e.target.value)}
            placeholder={placeholder}
            focusBorderColor={focusBorderColor || borderColorGray}
            name={name}
            _focus={{
                        backgroundColor: 'gray.50',
                    }}
          />
        </Box>
        {/* Message d'erreur */}
        {showError && (
        <Box>
          <Text px="1" color="red.600">{errorMessage}</Text>
        </Box>
            )}
      </Box>
    );
};

FormTextArea.propTypes = {
    label: PropTypes.string,
    borderColor: PropTypes.string,
    mb: PropTypes.number,
    ...fieldPropTypes,
};

FormTextArea.defaultProps = {
    hoverBackgroundcolor: 'gray.100',
    mb: 0,
    w: '100%',
    ...fieldDefaultProps,
};
