import { Stack } from '@chakra-ui/layout';
import { HDivider, Heading } from 'components';
import React from 'react';
import PropTypes from 'prop-types';

// TODO rename pour intégration skeleton
export const SubGroup = ({ title, children, ...rest }) => (
  <Stack direction="column" {...rest}>
    <Heading size="sm" textAlign="start">
      {title}
    </Heading>
    <HDivider />
    {children}
  </Stack>
);

SubGroup.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool])
    .isRequired,
};
