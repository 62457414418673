/**
 * @description https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react
 */

import React, { useContext, useState } from 'react';

/**
 * Creation du context
 */
export const EtapeContext = React.createContext([{}, () => { }]);


export const useEtapeContext = () =>
    useContext(EtapeContext);
/**
 * Déclaration des data
 * @param {*} props
 * @returns
 */
export const EtapeProvider = (props) => {

    /**
     * Déclaration des datas
     */
    const [etape, setEtape] = useState(1);


    return <EtapeContext.Provider value={{ etape, setEtape }} {...props} />;
};

