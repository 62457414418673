import Axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { APPENV } from 'config/config';
import { useTranslation } from 'react-i18next';
import { toastError, toastSuccess } from 'functions/common-scope/toast';

export const useDeleteValorisation = (props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { errorCallBack, successCallBack, id, userId } = {
    ...props,
  };
  return useMutation(
    () => Axios.delete(`${APPENV.HOST_API}/valorisation/${id}`),
    {
      onError: (err, newTodo, context) => {
        toastError({
          description: t('Erreur lors de la suppression'),
        });
        queryClient.setQueryData(
          ['valorisations', { userId }],
          context.previousTodos
        );
        return errorCallBack === undefined
          ? ''
          : errorCallBack(context.previousTodos);
      },
      onSuccess: async (data) => {
        toastSuccess({
          description: t('Supprimé'),
        });
        queryClient.invalidateQueries(['valorisations', { userId }]);
        return successCallBack === undefined ? '' : successCallBack(data);
      },
      onMutate: async (newTodo) => {
        console.log(newTodo);
        await queryClient.cancelQueries(['valorisations', { userId }]);

        const previousTodos = queryClient.getQueryData([
          'valorisations',
          { userId },
        ]);

        queryClient.setQueryData(['valorisations', { userId }], (old) => [
          ...old.filter((produit) => produit?.id !== newTodo),
        ]);
        return { previousTodos };
      },
    }
  );
};
