import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'config/axios';
import 'config/dayjs';
import 'config/i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ChakraProvider, Flex } from '@chakra-ui/react';
import { customTheme } from 'theme/customTheme';
import 'focus-visible/dist/focus-visible';
import { SpinnerLoading } from 'components';
import { CookiesProvider } from 'react-cookie';
import { AuthentificationProvider } from 'context/auth-context';
import { ReactQueryDevtools } from 'react-query/devtools';
import { EtapeProvider } from 'app/formulaire/context/EtapeProvider';
import { App } from './App';

const ScreenLoader = () => (
    <Flex w="100vw" h="100vh">
        <SpinnerLoading isLoading margin="auto" />
    </Flex>
);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <AuthentificationProvider>
                <ChakraProvider resetCSS theme={customTheme}>
                    <Suspense fallback={<ScreenLoader />}>
                        <CookiesProvider>
                            <EtapeProvider>
                                <App />
                            </EtapeProvider>
                        </CookiesProvider>
                    </Suspense>
                </ChakraProvider>
            </AuthentificationProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
