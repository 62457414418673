import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Text, VStack } from '@chakra-ui/react';
import { VDivider } from 'components/divider/VDivider';
import { HDivider } from 'components/divider/HDivider';

export const TimelineItem = ({ isLast, children, direction, title, ...rest }) => {

    const DividerTimeline = () => {
        if (direction === "column") {
            return <VDivider minH="20px" minW="3px" bgColor="defaultTheme.500" />;
        }
        return <HDivider maxW={{ base: "10px", md: "20px" }} minH={{ base: "5px", md: "3px" }} bgColor="gray.500" />

    };

    return (
        <VStack w="100%" {...rest}>
            <Text>{title}</Text>
            <Stack m={0} spacing={0} alignItems="center" w="100%" direction={direction} {...rest}>
                <DividerTimeline />
                {children}
                <DividerTimeline />
            </Stack>
        </VStack>
    );
};

TimelineItem.propTypes = {
    isLast: PropTypes.bool,
    children: PropTypes.instanceOf(Object),
    direction: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
    title: PropTypes.string,
};

TimelineItem.defaultProps = {
    isLast: false,
    children: (
        <Stack
            alignItems="center"
            border="1px solid gray"
            minH="30px"
            borderRadius="10px"
            alignSelf="stretch"
        >
            <Text>Item</Text>
        </Stack>
    ),
    direction: "vertical",
    title: ""

};
