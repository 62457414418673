import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Input,
    Text,
    Stack,
} from '@chakra-ui/react';
import {
    ModalAppsBody,
    ModalAppsFooter,
    ModalAppsHeader,
} from 'components/modal';

export const ModalCreateProduit = ({ isOpen, onClose, onValidSubmit }) => {
    const [value, setValue] = useState(null);

    const [isValid, setIsValid] = useState(false);

    return (
        <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalAppsHeader title="Création du produit" />
                <ModalCloseButton />
                <ModalAppsBody>
                    <Stack>
                        <Text as="b">Nom du produit</Text>
                        <Input onChange={(e) => { setValue(e.target.value); setIsValid(e.target.value !== null) }} isInvalid={!isValid} />
                    </Stack>
                </ModalAppsBody>
                <ModalAppsFooter
                    labelCancel="Annuler"
                    label="Créer"
                    onCancel={onClose}
                    isDisabled={!isValid}
                    onValid={() => onValidSubmit(value)}
                />
            </ModalContent>
        </Modal>
    );
}

ModalCreateProduit.propTypes = {
    onClose: PropTypes.func,
    onValidSubmit: PropTypes.func,
    isOpen: PropTypes.bool,
};
ModalCreateProduit.defaultProps = {
    isOpen: false,
    onClose: () => { },
    onValidSubmit: () => { },
};
