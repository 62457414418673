import React from 'react';
import PropTypes from 'prop-types';
import { Flex, IconButton } from '@chakra-ui/react';
import {
  FormGroup, InputFile, InputFilePlaceholder, Icon, Heading, Card, CardBody,
} from 'components';
import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import { FaFile, FaTimes, FaPlus } from 'react-icons/fa';

const propTypes = {
  accept: PropTypes.string,
  ...fieldPropTypes,
};

const defaultProps = {
  accept: null,
  ...fieldDefaultProps,
};

export const FieldUploader = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    setValue,
    value,
  } = useField(props);

  const {
    children, label, required, placeholder, helper, accept, ...otherProps
  } = props;

  const [isTouched, setIsTouched] = React.useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    ...otherProps,
  };

  return (
    <FormGroup {...formGroupProps}>
      <InputFile
        accept={accept}
        onBlur={() => {
          setIsTouched(true);
        }}
        onChange={(e) => {
          setValue(e.target.files[0]);
        }}
      >
        {value ? (
          <Card>
            <CardBody position="relative">
              <Flex h="100%" w="100%" justify="center" align="center">
                <Icon mr="3" icon={FaFile} color="brand.500" />
                <Heading
                  overflow="hidden"
                  minW="0"
                  pr="4"
                  textOverflow="ellipsis"
                  whiteSpace="no-wrap"
                  size="sm"
                >
                  {value.name}
                </Heading>
              </Flex>
              <IconButton
                position="absolute"
                variant="ghost"
                borderRadius="full"
                my="auto"
                top="0"
                bottom="0"
                right="2"
                icon={FaTimes}
                onClick={(e) => {
                  e.preventDefault();
                  setValue(null);
                }}
              />
            </CardBody>
          </Card>
        ) : (
          <InputFilePlaceholder>
            <Icon color="brand.500" icon={FaPlus} mr="2" />
            <Heading size="sm" as="div">{children}</Heading>
          </InputFilePlaceholder>
        )}
      </InputFile>
    </FormGroup>
  );
};

FieldUploader.propTypes = propTypes;
FieldUploader.defaultProps = defaultProps;
