import React from 'react';
import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import { Input, InputGroup, Stack, Textarea } from '@chakra-ui/react';
import { FormGroup } from 'components';
import PropTypes from 'prop-types';
import { ButtonDeleteInline } from 'components/button';

const propTypes = {
  textAlign: PropTypes.string,
  ...fieldPropTypes,
};

const defaultProps = {
  textAlign: 'left',
  ...fieldDefaultProps,
};

export const FieldInputConfirmDelete = (props) => {
  const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(
    props
  );
  const {
    label,
    type,
    required,
    isDisabled,
    placeholder,
    textAlign,
    children,
    inputWidth,
    valueInput,
    ...otherProps
  } = props;

  const { handleConfirm } = props;

  const [isTouched, setIsTouched] = React.useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  const formGroupProps = {
    errorMessage,
    id,
    isRequired: !!required,
    label,
    showError,
    isDisabled,
    ...otherProps,
  };

  const getElementAndProps = () => {
    if (type === 'textarea') {
      return [
        Textarea,
        {
          height: '1.8rem',
        },
      ];
    }
    return [Input, {}];
  };

  const [Element, inputExtraProps] = getElementAndProps();

  return (
    <FormGroup {...formGroupProps}>
      <InputGroup alignItems="center">
        <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
          {children}
          <Element
            textAlign={textAlign}
            id={id}
            type={type || 'text'}
            value={(valueInput || value) ?? ''}
            onChange={(e) => setValue(e.target.value)}
            onBlur={() => {
              setIsTouched(true);
            }}
            focusBorderColor="brand.500"
            placeholder={placeholder}
            aria-invalid={showError}
            aria-required={!!required}
            aria-describedby={showError ? `${id}-error` : null}
            width={inputWidth}
            {...inputExtraProps}
          />

          <ButtonDeleteInline size="xl" handleConfirm={handleConfirm} />
        </Stack>
      </InputGroup>
    </FormGroup>
  );
};

FieldInputConfirmDelete.propTypes = propTypes;
FieldInputConfirmDelete.defaultProps = defaultProps;
