const isoCountriesLanguages = require('@hotosm/iso-countries-languages');
export class Countries {
  static getCountries() {
    const countriesInFrench = isoCountriesLanguages.getCountries('fr');

    const countries = Object.entries(countriesInFrench)?.map((country) => ({
      value: country[0],
      label: country[1],
    }));
    return countries;
  }
}
