import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

export const InputFile = ({
  children, multiple, accept, ...props
}) => (
  <Box
    as="label"
    display="block"
    w="100%"
    position="relative"
    cursor="pointer"
    {...props}
  >
    <Box
      as="input"
      zIndex="-1"
      position="absolute"
      opacity="0"
      h="0.1px"
      w="0.1px"
      overflow="hidden"
      type="file"
      accept={accept}
      multiple={multiple}
    />
    {children}
  </Box>
);

InputFile.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  multiple: PropTypes.bool,
};

InputFile.defaultProps = {
  accept: null,
  children: null,
  multiple: false,
};
