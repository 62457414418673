import {
    Stack,
    HStack,
} from '@chakra-ui/react';
import { Formiz, useForm } from '@formiz/core';
import { FieldInput, FieldSelect } from 'components';
import { FieldInputTextArea } from 'components/fields/FieldInputTextArea';
import { FormBox } from 'components/formulaire/FormBox';
import React from 'react';
import PropTypes from 'prop-types';
import { ButtonDelete } from 'components/button';
import { ButtonSubmit } from 'components/button/ButtonSubmit';
import { useCreateValorisation } from 'app/formulaire/services/valorisation/useCreateValorisation';
import { useUpdateValorisation } from 'app/formulaire/services/valorisation/useUpdateValorisation';
import { useDeleteValorisation } from 'app/formulaire/services/valorisation/useDeleteValorisation';
import { useAuthentificationContext } from 'context/auth-context';

export const FormValorisation = ({ produit }) => {

    const { user } = useAuthentificationContext();
    const valorisationForm = useForm({ subscribe: 'form' });


    const optionsUniteVolConsomme = [
        { value: 'tonnes', label: 'Tonnes' },
        { value: 'metre_cube', label: 'm³' },
    ];

    const optionsBool = [
        { label: 'Oui', value: 'true' },
        { label: 'Non', value: 'false' },
    ];

    const optionsSite = [
        { label: 'Sur site', value: 'site' },
        { label: 'Hors site', value: 'hors_site' },
    ];

    const optionsCategorie = [
        { label: 'Coproduit', value: 'coproduit' },
        { label: 'Déchet', value: 'dechet' },
        { label: 'Déchet ultime', value: 'dechet_ultime' },
    ];

    const optionsTypeTraitement = [
        { label: 'Stockage', value: 'coproduit' },
        { label: 'Incinération', value: 'dechet' },
        { label: 'Valorisation énergétique', value: 'valorisation_energetique' },
        { label: 'Recyclage', value: 'recyclage' },
        { label: 'Ré-utilisation', value: 'reutilisation' },
        { label: 'Vente', value: 'vente' },
    ];


    const { mutate: mutationCreate, isLoading: isLoadingMutateCreate } = useCreateValorisation({ userId: user?.utilisateurId });
    const { mutate: mutationUpdate, isLoading: isLoadingMutateUpdate } = useUpdateValorisation({ id: produit?.id, userId: user?.utilisateurId });
    const { mutate: mutationDelete } = useDeleteValorisation({ id: produit?.id, userId: user?.utilisateurId });


    const removeProduit = () => {
        mutationDelete();
    };

    const handleSubmit = (values) => {

        if (produit?.id !== undefined) {
            mutationUpdate(values);
        } else {
            mutationCreate(values);
        }
    };

    // console.log(`${produit?.id}.nom`);

    return (
        <Formiz key={produit} autoForm connect={valorisationForm} onValidSubmit={handleSubmit}>
            <Stack spacing={4} key={produit?.id}>
                <Stack spacing={8}>
                    <FormBox
                        title="questionnaire:chapters.descriptionProduit"
                        actionHeader={
                            user?.canUpdate && (
                                <ButtonDelete
                                    label="Supprimer"
                                    action={() => removeProduit(produit)}
                                />)
                        }
                    >
                        <Stack spacing={4}>
                            <HStack>
                                <HStack alignItems="self-start">
                                    <FieldInput
                                        name="nom"
                                        label="Nom du produit"
                                        defaultValue={produit?.nom}
                                        key={`form_valorisation_${produit?.id}_nom`}
                                    />
                                </HStack>
                            </HStack>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldSelect
                                    name="categorie_produit"
                                    label="Catégorie de produit"
                                    options={optionsCategorie}
                                    defaultValue={produit?.categorie}
                                    key={`form_valorisation.${produit?.id}.categorie`} />
                            </Stack>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldInput name="purete" label="Pureté (%)" numericOnly withPercentage defaultValue={produit?.purete} />
                                <FieldInput name="concentration" label="Concentration (%)" numericOnly withPercentage defaultValue={produit?.concentration} />
                            </Stack>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>

                                <FieldInput name="volume" label="Volumes /an" defaultValue={produit?.volumeAnnuel} />
                                <FieldSelect
                                    name="unite_volume"
                                    label="Unité du vol. conso. (tonnes ou m3)"
                                    options={optionsUniteVolConsomme}
                                    defaultValue={produit?.volumeConsommeUnite}
                                />
                            </Stack >

                        </Stack>
                    </FormBox>
                    <FormBox title="questionnaire:chapters.traitement">
                        <Stack spacing={4}>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldSelect
                                    name="type_traitement"
                                    label="Type de traitement/utilisation actuel"
                                    options={optionsTypeTraitement}
                                    defaultValue={produit?.typeTraitement}
                                />
                                <FieldSelect name="traitement_sur_site" label="Traitement sur votre site/hors site" options={optionsSite} defaultValue={produit?.traitementSurSite} />
                            </Stack>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldInputTextArea
                                    name="contraintes_traitement"
                                    label="Contraintes rencontrées dans le traitement / utilisation"
                                    defaultValue={produit?.contraintesTraitement}
                                />
                                <FieldInputTextArea
                                    name="commentaire_traitement"
                                    label="Commentaires/remarques"
                                    defaultValue={produit?.commentaireTraitement}
                                />
                            </Stack>
                        </Stack>
                    </FormBox>

                    <FormBox title="questionnaire:chapters.evolution">
                        <Stack spacing={4}>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldSelect
                                    name="autre_voie_traitement"
                                    label="Recherchez-vous une autre voie de traitement pour ce déchet/co-produit ?"
                                    options={optionsBool}
                                    defaultValue={produit?.autreVoieTraitement}
                                />

                            </Stack>
                            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                                <FieldInputTextArea
                                    name="contraintes_evolution"
                                    label="Contraintes à prendre en compte pour la recherche d'une autre voie de traitement ?"
                                    defaultValue={produit?.contraintesEvolution}
                                />
                                <FieldInputTextArea
                                    name="commentaire_evolution"
                                    label="Commentaires/remarques"
                                    defaultValue={produit?.commentaireEvolution}
                                />
                            </Stack>
                        </Stack>
                    </FormBox>
                </Stack>
                {user?.canUpdate && (
                    <Stack alignItems="flex-end">
                        <ButtonSubmit
                            label="Enregistrer"
                            onClick={valorisationForm.submit}
                            iconOnly={false}
                            disabled={!valorisationForm.isValid}
                            isLoading={isLoadingMutateCreate || isLoadingMutateUpdate}
                        />
                    </Stack>
                )}
            </Stack>
        </Formiz>
    );
};

FormValorisation.propTypes = {
    produit: PropTypes.instanceOf(Object),
};

FormValorisation.defaultProps = {
    produit: undefined,
};
