import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Stack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const ButtonDelete = ({ action, label, canDelete }) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    if (!canDelete) {
        return <></>;
    }
    return (
        <>
            <Button onClick={onOpen} colorScheme="danger">
                {t(label)}
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('Supprimer')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>{t('Êtes-vous sûr de vouloir supprimer ?')}</ModalBody>

                    <ModalFooter>
                        <Stack direction="row" justifyContent="flex-start" spacing={4}>
                            <Button colorScheme="success" onClick={() => {
                                action();
                                onClose();
                            }}>
                                {t('Oui')}
                            </Button>
                            <Button colorScheme="danger" onClick={onClose}>
                                {t('Non')}
                            </Button>
                        </Stack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

ButtonDelete.propTypes = {
    action: PropTypes.func,
    label: PropTypes.string,
    canDelete: PropTypes.bool,
};

ButtonDelete.defaultProps = {
    action: () => null,
    label: 'app.components.buttons.default.delete',
    canDelete: true,
};
