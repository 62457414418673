import Axios from 'axios';
import { useQuery } from 'react-query';
import { APPENV } from 'config/config';

export const useGetValorisations = (userId) =>
  useQuery(
    ['valorisations', { userId }],
    async () => {
      const result = await Axios.get(`${APPENV.HOST_API}/valorisations`);
      return result?.data;
    },
    { enabled: !!userId },
    []
  );
