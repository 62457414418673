import React from 'react';
import PropTypes from 'prop-types';
import { ButtonApp } from './ButtonApp';

export const ButtonSubmit = (props) => {
  const { label, display, isLoading, backgroundColor, color, ...rest } = props;
  return (
    <>
      {display && (
        <ButtonApp
          backgroundColor={backgroundColor}
          isLoading={isLoading}
          label={label}
          color={color}
          _hover={{ backgroundColor: 'defaultTheme.700' }}
          {...rest}
        />
      )}
      {!display && <></>}
    </>
  );
};

ButtonSubmit.propTypes = {
  label: PropTypes.string,
  display: PropTypes.bool,
  isLoading: PropTypes.bool,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
};

ButtonSubmit.defaultProps = {
  label: 'app:components.buttons.default.create',
  display: true,
  isLoading: false,
  backgroundColor: 'defaultTheme.500',
  color: 'white',
};
