import dayjs from 'dayjs';
import { isNullOrUndefined } from 'functions/common-scope/ValidationType';
import { v4 } from 'uuid';

export const formatDateDb = (date) => {
  if (date !== null) {
    return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
  }
  return null;
};

export const YearDate = (date, lang) => {
  if (isNullOrUndefined(date)) {
    return null;
  }
  return dayjs(date).locale(`'${lang}'`).format('YYYY');
};

export const uuidV4ToString = (uuidv4) => {
    let uuid = uuidv4;
    if(uuidv4 === undefined) {
        uuid = v4();
    }
    return uuid.replace('-', '');
}


export const getListeBool = () => {
  const listeBoolChoice = [
    {
      label: 'Oui',
      value: 'Oui',
    },
    {
      label: 'Non',
      value: 'Non',
    },
  ];
  return listeBoolChoice;
};

export const FormatDate = (date, lang) => {
  if (isNullOrUndefined(date)) {
    return null;
  }
  return dayjs(date).locale(`'${lang}'`).format('DD MMM YYYY');
};

export const search = (nameKey, myArray) => {
  for (let i = 0; i < myArray.length; i + 1) {
    if (myArray[i].name === nameKey) {
      return myArray[i];
    }
  }

  return '';
};

export const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const isNumeric = (str) => {
  if (typeof str !== 'string') return false; // we only process strings!
  return !Number.isNaN(str) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    && !Number.isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
};

export const findById = (arr, idToSearch) => arr.filter((item) => item.id === idToSearch);
export const findByEmail = (arr, emailToSearch) => arr.filter((item) => item.email === emailToSearch);

export const containsObject = (obj, list) => {
  let i;
  for (i = 0; i < list.length; i + 1) {
    if (list[i] === obj) {
      return true;
    }
  }

  return false;
};

export const isValidDatesInter = (dateTravaux, dateReponse) => dateTravaux >= dateReponse;

export const getQueryParamsValue = (queryParams, searchValue, defaultValue = '') => {
  if (queryParams.get(searchValue) !== null && queryParams.get(searchValue) !== 'null') {
    return queryParams.get(searchValue);
  }
  return defaultValue;
};

export const getUnique = (arr, comp) => {
  // store the comparison  values in array
  const unique = arr.map((e) => e[comp])

    // store the indexes of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the false indexes & return unique objects
    .filter((e) => arr[e]).map((e) => arr[e]);

  return unique;
};

/**
 * Coupe une châine de caractère si elle dépasse une certaine longueur monDoc[...].pdf
 * @param {String} str
 */
export const wrapStr = (str, length) => {
  if (str === null || str === undefined) {
    return str;
  }
  if (str.length > length) {
    let newStr = str.substring(0, `${length}`);
    newStr += '[...]';
    return newStr;
  }
  return str;
};
