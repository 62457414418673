import React from 'react';
import { Button, Text, Select, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContentLayout } from 'layout';
import { updateLanguage } from 'config/i18next';
import { LayoutDefault } from 'layout/LayoutDefault';

export const Profile = () => {
  const { t } = useTranslation();

  const handleChangeLang = (event) => {
    updateLanguage(event.target.value);
  };

  return (
    <LayoutDefault>
      <ContentLayout>
        <Flex
          flexDirection="column"
          p={4}
          mx="auto"
          w={{
            base: '100%',
            lg: '70%',
            xl: '50%',
          }}
        >
          <Flex w="100%" mb={{ base: 2 }}>
            <Text my="auto" mr="1rem">
              {t('label.langue', { defaultValue: 'Langue' })}
            </Text>
            <Select defaultValue="fr" onChange={handleChangeLang}>
              <option value="fr">Français</option>
            </Select>
          </Flex>
          <Flex w="100%">
            <Button
              as={Link}
              to="/signout"
              w="full"
              mt={3}
              colorScheme="danger"
            >
              {t('logout', { defaultValue: 'Logout' })}
            </Button>
          </Flex>
        </Flex>
      </ContentLayout>
    </LayoutDefault>
  );
};
