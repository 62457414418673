import { Stack, HStack, Text } from '@chakra-ui/react';
import { FieldInput } from 'components';
import React from 'react';
import PropTypes from 'prop-types';
import { isEmail } from '@formiz/validations';

export const FormIdentification = ({ identification }) => (

    <Stack spacing={8}>
        <HStack>
            <FieldInput name="nom" label="Nom" defaultValue={identification?.nom} />
            <FieldInput name="prenom" label="Prénom" defaultValue={identification?.prenom} />
            <FieldInput name="fonction" label="Fonction" defaultValue={identification?.fonction} />
        </HStack>
        <HStack>
            <FieldInput name="tel" label="N° Téléphone" defaultValue={identification?.tel} />
            <FieldInput
                name="email"
                label="Adresse électronique"
                defaultValue={identification?.email}
                validations={[{
                    rule: isEmail(),
                    message: 'Adresse électronique invalide',
                },]} />
        </HStack>
        <HStack >
            <FieldInput name="nom_entreprise" label="Dénomination sociale de l'entreprise" defaultValue={identification?.nomEntreprise} />
        </HStack>
        <Stack alignItems="start">
            <Text as="i"> Le questionnaire concerne uniquement les sites normands.</Text>
            <FieldInput name="localisation_sites" label="Localisation du site en Normandie pour lequel vous répondez (ville)" defaultValue={identification?.localisationSites} />
        </Stack>

    </Stack>


);


FormIdentification.propTypes = {
    identification: PropTypes.instanceOf(Object),
};

FormIdentification.defaultProps = {
    identification: undefined,
};
