import React, { useState } from 'react';

import { useAuthentificationContext } from 'context/auth-context';
import { Redirect } from 'react-router-dom';
import { SpinnerLoading } from 'components';
import { FormLogin } from './_partials/FormLogin';
import { FormReseting } from './_partials/FormReseting';
import { FormLayout } from './_partials/FormLayout';

export const SignIn = () => {
  const [isReseting, setIsReseting] = useState(false);
  const [isErrorLogin, setIsErrorLogin] = useState(false);
  const { initialisation, isLogged } = useAuthentificationContext();

  if (!initialisation && !isErrorLogin && isLogged) {
    return <Redirect to="/home" />;
  }

  const isFormLogin = !isLogged && !initialisation && !isReseting;
  const isFormReseting = !isLogged && !initialisation && isReseting;

  return (
    <FormLayout>
      <SpinnerLoading isLoading={!isFormLogin && !isFormReseting}>
        {isFormLogin && (
          <FormLogin
            setIsReseting={setIsReseting}
            setIsErrorLogin={setIsErrorLogin}
          />
        )}
        {isFormReseting && (
          <FormReseting
            setIsReseting={setIsReseting}
            setIsErrorLogin={setIsErrorLogin}
          />
        )}
      </SpinnerLoading>
    </FormLayout>
  );
};
