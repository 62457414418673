import Axios from 'axios';
import { APPENV } from 'config/config';
import { toastError, toastSuccess } from 'functions/common-scope/toast';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

export const useCreateIdentification = (props) => {
  const { errorCallBack, successCallBack, userId } = {
    ...props,
  };
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    (data) => Axios.post(`${APPENV.HOST_API}/identification`, data),
    {
      onError: () => {
        toastError({
          description: t('Erreur lors de la sauvegarde'),
        });
        return errorCallBack === undefined ? '' : errorCallBack();
      },
      onSuccess: () => {
        toastSuccess({
          description: t('Sauvegardé'),
        });
        queryClient.invalidateQueries(['identification', { userId }]);

        return successCallBack === undefined ? '' : successCallBack();
      },
    }
  );
};
