export const TableApps = {
  baseStyle: {
    table: {
      width: '100%',
    },
    head: {
      display: 'inline-block',
      width:"100%",
    },
    td:{
      display: 'flex',
      alignItems: 'center',
      justifyContent:'center',
    }
  },
  parts: ['table', 'head', 'body'],
  variants: {
    dft: {
      head: {
        padding: "10px",
        borderRadius: '20px 20px 0 0',
        background: '#C4C4C4',
        color: 'white',
      },
      td: {
        padding: '5px',
        border: 'solid 1px #C4C4C4',
        background: '#ffffff',
      },
      footer:{
        display: 'none'
      },
      btnAdd: {
        color: 'white'
    }
    },
    symm: {
      table: {
        border: 'solid 1px #2d71b8',
      },
      head: {
        borderBottom: 'solid 3px #36b265',
        background: '#2d71b8',
        color: 'white',
        fontWeight: 'bold',
      },
      td: {
        padding: '10px',
        border: 'solid 1px gray',
        background: '#e3f3ff',
      },
    },
  },
  // On change le variant ici par application
  defaultProps: {
    variant: 'default',
  },
};
