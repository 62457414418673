import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';
import { Spinner } from 'components';

/**
 * Affiche un spinner et remplace celui-ci par le children
 * quand isLoading = false
 */
export const SpinnerLoading = ({
  size,
  color,
  isLoading,
  children,
  ...props
}) => {
  /**
   * Affiche le children s'il est présent
   */
  if (!isLoading) {
    return <>{children}</>;
  }

  return (
    <Box align="center" justify="center" p={2}>
      <Spinner size={size} color={color} {...props} />
    </Box>
  );
};

SpinnerLoading.propTypes = {
  isLoading: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  children: PropTypes.instanceOf(Object),
};

SpinnerLoading.defaultProps = {
  isLoading: true,
  size: 'md',
  color: 'brand.700',
  children: null,
};
