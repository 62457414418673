import {
    Tabs,
    TabList,
    TabPanels,
    TabPanel,
    Tab,
    Button,
    Text,
    useDisclosure,
    Stack,
    VStack,
    Flex,
} from '@chakra-ui/react';
import { useCreateProduitDemain } from 'app/formulaire/services/chimie-demain/useCreateProduitDemain';
import { useGetProduitsDemain } from 'app/formulaire/services/chimie-demain/useGetProduitsDemain';
import { InputSkeleton } from 'components/skeleton-loader/_partials/InputSkeleton';
import { useAuthentificationContext } from 'context/auth-context';
import React, { useCallback, useEffect, useState } from 'react';
import { toastError } from 'functions/common-scope/toast';
import { SwitchEtape } from '../_partials/SwitchEtape';
import { FormChimieDemain } from './_partials/FormChimieDemain';
import { ModalCreateProduit } from '../_partials/ModalCreateProduit';


export const TabChimieDemain = () => {

    const [tabIndex, setTabIndex] = React.useState(0);
    const [nomTechnique, setNomTechnique] = useState([]);
    const { user } = useAuthentificationContext();
    const { data, isLoading, isFetching } = useGetProduitsDemain(user?.utilisateurId);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleTabIndex = useCallback(() => {
        const index = data?.findIndex((element) => element?.nomTechnique === nomTechnique);
        setTabIndex(index === -1 ? 0 : index);
    }, [nomTechnique, data]);


    useEffect(() => {
        handleTabIndex();
    }, [handleTabIndex]);

    const { mutate: mutationCreate, isLoading: isLoadingMutateCreate } = useCreateProduitDemain({ userId: user?.utilisateurId });


    const addProduit = (value) => {
        setNomTechnique(value);
        if (value === null) {
            toastError({
                description: 'Champ obligatoire',
            });
        } else {
            mutationCreate({ nomTechnique: value });
            onClose();
        }

    };

    const handleTabsChange = (index) => setTabIndex(index);


    if (isLoading || isFetching) {
        return (<InputSkeleton />);
    }

    return (
        <>
            <VStack alignItems="flex-start">
                <Text as="b">
                    Objectif :
                </Text>
                <Text as="i">
                    Identifier les produits chimiques non consommés aujourd'hui mais dont les industriels auront besoin demain (ex : projets de décarbonation de vos procédés, molécules biosourcées, chimie du CO2, recyclage chimique…)
                </Text>
            </VStack>
            <Stack alignItems="flex-end">
                <SwitchEtape>
                    {user?.canUpdate && (
                        <Button onClick={onOpen} isLoading={isLoadingMutateCreate} backgroundColor="brand.500" color="white">
                            {data?.length === 0 ? `Saisir un produit` : `Ajouter un produit`}
                        </Button>
                    )}
                </SwitchEtape>
            </Stack>
            {!isLoading && (
                <Tabs index={tabIndex} onChange={handleTabsChange}>
                    <TabList>
                        {data?.map((produit) => (
                            <Tab
                                position="relative"
                                fontSize="11px"
                                backgroundColor="#E8E8E8"
                                boxShadow="5px 0px 4px -3px rgba(0, 0, 0, 0.25)"
                                borderRadius="10px 10px 0 0"
                                borderBottom="none"
                                borderWidth="1px"
                                borderColor="gray.100"
                                fontWeight="bold"
                                _selected={{
                                    color: "brandSecondary.500",
                                    backgroundColor: "#FFF",
                                    zIndex: "50",
                                }}
                                as={Flex}
                                cursor="pointer"
                                key={`tab${produit?.id}`}>
                                <Text>{produit.nomTechnique}</Text>
                            </Tab>
                        ))}
                    </TabList>

                    <TabPanels>
                        {data?.map((produit) => (
                            <TabPanel key={`tabpanel${produit?.id}`}>
                                <FormChimieDemain
                                    produit={produit}
                                />
                            </TabPanel>
                        ))}
                    </TabPanels>
                </Tabs>
            )}
            <ModalCreateProduit onClose={onClose} isOpen={isOpen} onValidSubmit={addProduit} />
        </>
    );
};
