import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex, useTheme, Box,
} from '@chakra-ui/react';
import { FaArrowLeft } from 'react-icons/fa';
import { Heading } from 'components';
import { useTranslation } from 'react-i18next';

const propTypes = {
  title: PropTypes.string.isRequired,
  onGoBack: PropTypes.func,
  children: PropTypes.node,
  isForm: PropTypes.bool,
  cursor: PropTypes.string,
};

const defaultProps = {
  onGoBack: null,
  children: null,
  isForm: false,
  cursor: '',
};

export const PageHeader = ({
  title,
  onGoBack,
  children,
  isForm,
  cursor,
  ...rest
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Flex
      h={{ base: '', md: theme.headerHeight }}
      // w={{
      //   base: '100%', xl: isForm ? '80%' : '100%',
      // }}
      justifyContent={{
        base: 'center', sm: 'center', md: 'center', lg: 'start', xl: 'start',
      }}
      boxShadow="0px 2px 14px 0px rgba(0,143,89,0.02)"
      alignItems="center"
      // position="fixed"
      zIndex="1"
      {...rest}
    >

      <Flex flex={1} alignItems="center" {...rest}>

        <Flex
          alignItems="center"
          cursor={cursor}
          onClick={onGoBack}
        >
          {onGoBack && (
          <>
            <Box
              rounded="0.3rem"
              p="0.5rem"
              _hover={{
            backgroundColor: 'gray.50',
          }}
            >
              <Box
                h="1.5rem"
                w="1.5rem"
                as={FaArrowLeft}
                color="green.800"
              />
            </Box>

          </>
)}
          <Heading
            mr={4}
            fontSize={{
        base: 'sm', sm: 'md', md: 'lg', lg: 'lg', xl: 'xl',
       }}
          >
            {`${t(title)}`}
          </Heading>
        </Flex>

        {children}

      </Flex>
    </Flex>
  );
};

PageHeader.propTypes = propTypes;
PageHeader.defaultProps = defaultProps;
