import Axios from 'axios';
import { APPENV } from 'config/config';
import { toastError, toastSuccess } from 'functions/common-scope/toast';
import { useTranslation } from 'react-i18next';
import { QueryCache, useMutation, useQueryClient } from 'react-query';

export const useUpdateFinalisation = (props) => {
  const { t } = useTranslation();
  const { errorCallBack, successCallBack, id, userId } = {
    ...props,
  };

  const queryClient = useQueryClient();

  return useMutation(
    (data) => Axios.put(`${APPENV.HOST_API}/finalisation/${id}`, data),
    {
      onError: () => {
        toastError({
          description: t('Erreur lors de la sauvegarde'),
        });
        return errorCallBack === undefined ? '' : errorCallBack();
      },
      onSuccess: () => {
        toastSuccess({
          description: t('Sauvegardé'),
        });
        queryClient.invalidateQueries(['finalisation', { userId }]);
        return successCallBack === undefined ? '' : successCallBack();
      },
    }
  );
};
