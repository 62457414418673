import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Box,
} from '@chakra-ui/react';
import React from 'react';
import { MdMoreVert, MdModeEdit } from 'react-icons/md';
import { CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export const ToolsButton = (props) => {
  const { setEditMode, setDeleteMode, resetMode, ...rest } = props;
  const { t } = useTranslation();

  return (
    <Menu>
      <MenuButton
        border="0px"
        as={IconButton}
        aria-label="Options"
        icon={<Box as={MdMoreVert} />}
        variant="outline"
        {...rest}
      />
      <MenuList fontWeight="light" zIndex="2000">
        {/* Bouton for edit the file */}
        <MenuItem
          icon={<Box as={MdModeEdit} />}
          onClick={() => setEditMode(true)}
        >
          {t('edit')}
        </MenuItem>

        {/* Bouton for delete the file */}
        <MenuItem icon={<DeleteIcon />} onClick={setDeleteMode}>
          {t('delete')}
        </MenuItem>

        {/* Bouton for delete the file */}
        <MenuItem icon={<CloseIcon />} onClick={resetMode}>
          {t('cancel')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

ToolsButton.propTypes = {
  setEditMode: PropTypes.func,
  setDeleteMode: PropTypes.func,
  resetMode: PropTypes.func,
};

ToolsButton.defaultProps = {
  setEditMode: () => null,
  setDeleteMode: () => null,
  resetMode: () => null,
};
