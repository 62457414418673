import React from 'react';
import PropTypes from 'prop-types';

export const FormatNumber = ({
  value,
  deci,
  currency,
  percent,
  defaultValue,
  locale,
  transformZeroToTiret,
}) => {
  const aucuneValeur = <>--</>;

  const numberFormatProp = () => {
    if (currency) {
      return {
        style: 'currency',
        currency: 'EUR',
      };
    }

    if (percent) {
      return {
        style: 'percent',
      };
    }

    return {};
  };

  const valueParsed = parseFloat(value);

  if (value === null) {
    return aucuneValeur;
  }

  if (Number.isNaN(valueParsed)) {
    return defaultValue || <>NaN</>;
  }

  if (transformZeroToTiret && valueParsed === 0) {
    return aucuneValeur;
  }

  const formatedValue = new Intl.NumberFormat(locale, {
    ...numberFormatProp({ currency, percent }),
    minimumFractionDigits: deci,
    maximumFractionDigits: deci,
  }).format(valueParsed ?? defaultValue);
  return <>{formatedValue}</>;
};

FormatNumber.propTypes = {
  deci: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  locale: PropTypes.string,
  currency: PropTypes.bool,
  percent: PropTypes.bool,
  transformZeroToTiret: PropTypes.bool,
};

FormatNumber.defaultProps = {
  value: '--',
  deci: 0,
  currency: false,
  percent: false,
  defaultValue: '--',
  locale: 'fr-FR',
  transformZeroToTiret: false,
};
