import React, { useEffect, useState } from 'react';

import { useAuthentificationContext } from 'context/auth-context';
import { Redirect } from 'react-router-dom';
import { Stack, Text } from '@chakra-ui/react';
import { SpinnerLoading } from 'components';
import { useTranslation } from 'react-i18next';
import { FormLayout } from './_partials/FormLayout';

export const SignOut = () => {
  const { logOut } = useAuthentificationContext();
  const [runRedirect, setRunRedirect] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    logOut();
    const timer = setTimeout(() => {
      setRunRedirect(true);
    }, 2000);
    return () => clearTimeout(timer);
  });

  if (runRedirect) {
    return <Redirect to="/login" />;
  }

  return (
    <FormLayout>
      <Stack m="auto">
        <Text m="auto">
          {t('app.components.signin.signout', {
            defaultValue: 'Déconnexion en cours',
          })}
        </Text>
        <SpinnerLoading />
      </Stack>
    </FormLayout>
  );
};
