import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useAuthentificationContext } from 'context/auth-context';
import { Stack } from '@chakra-ui/react';
import { Spinner } from 'components';
import { UnAuthorized } from 'pages/UnAuthorized';
import PropTypes from 'prop-types';
import { PageContent } from 'layout/page-content';

export const Authenticated = (props) => {
  const { authorisedUsers } = props;
  const { pathname } = useLocation();
  const { initializing, user } = useAuthentificationContext();

  return (
    <>
      {initializing && (
        <PageContent>
          <Stack flex={1} align="center" justify="center">
            <Spinner size="xl" color="brand.700" />
          </Stack>
        </PageContent>
      )}

      {user !== undefined &&
        user !== null &&
        !initializing &&
        (authorisedUsers.includes(user.profil) ||
          authorisedUsers.includes('all') ||
          user.isAdmin) && <Route {...props} />}

      {user !== undefined &&
        user !== null &&
        !initializing &&
        !authorisedUsers.includes(user.profil) &&
        !authorisedUsers.includes('all') &&
        !user.isAdmin && <UnAuthorized />}

      {!initializing && (user === undefined || user === null) && (
        <Redirect to={{ pathname: '/login', state: { from: pathname } }} />
      )}
    </>
  );
};
Authenticated.propTypes = {
  authorisedUsers: PropTypes.instanceOf(Array).isRequired,
};
