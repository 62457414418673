import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ImFileExcel } from 'react-icons/im';
import { ButtonApp } from './ButtonApp';

export const ButtonExtract = (props) => {
    const { t } = useTranslation();

    const { label, onClick, ...rest } = props;

    return (
        <ButtonApp
            border="1px"
            borderColor="gray.200"
            leftIcon={<ImFileExcel filter="saturate(0%) brightness(0%) contrast(100%)" />}
            backgroundColor="white"
            color="black"
            onClick={onClick}
            _hover={{ backgroundColor: 'gray.200' }}
            label={t(label)}
            {...rest}
        />
    );
};

ButtonExtract.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
};

ButtonExtract.defaultProps = {
    label: 'Exporter les résultats du questionnaire',
    onClick: () => null,
};
