import React from 'react';
import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import {
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
} from '@chakra-ui/react';
import { FormGroup } from 'components';
import { FaSave } from 'react-icons/fa';
import PropTypes from 'prop-types';

const propTypes = {
    textAlign: PropTypes.string,
    maxLength: PropTypes.number,
    numericOnly: PropTypes.bool,
    withPercentage: PropTypes.bool,
    ...fieldPropTypes,
};

const defaultProps = {
    textAlign: 'left',
    maxLength: undefined,
    numericOnly: false,
    withPercentage: false,
    ...fieldDefaultProps,
};

export const FieldInput = (props) => {
    const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(
        props
    );
    const {
        label,
        type,
        required,
        isDisabled,
        placeholder,
        textAlign,
        children,
        maxLength,
        numericOnly,
        withPercentage,
        inputWidth,
        ...otherProps
    } = props;


    const { activeRightButton, handleClickRightButton, customId } = props;

    const [isTouched, setIsTouched] = React.useState(false);
    const showError = !isValid && (isTouched || isSubmitted);

    const validRulesForPercentage = (character) => {
        const newValue = value.concat(character);
        if (newValue.includes('100') && newValue !== "100") {
            return false;
        }

        if (parseFloat(newValue) === 100) {
            return true;
        }

        return (/^\d{1,2}(?:,\d{0,2})?$/
            .test(newValue));

    }

    const formGroupProps = {
        errorMessage,
        id,
        isRequired: !!required,
        label,
        showError,
        isDisabled,
        ...otherProps,
    };

    return (
        <FormGroup {...formGroupProps}>
            <InputGroup>
                {children}
                <Input
                    textAlign={textAlign}
                    id={id}
                    type={type || 'text'}
                    onKeyPress={(event) => {
                        if (numericOnly === true) {
                            if (!/^[-,0-9]+$/.test(event.key)) {
                                event.preventDefault();
                            }
                            if (withPercentage === true) {

                                if (!validRulesForPercentage(event.key)) {
                                    event.preventDefault();
                                }
                            }
                        }
                    }}
                    value={value ?? ''}
                    onChange={(e) => setValue(e.target.value)}
                    onBlur={() => {
                        setIsTouched(true);
                    }}
                    maxLength={maxLength}
                    focusBorderColor="brand.500"
                    placeholder={placeholder}
                    aria-invalid={showError}
                    aria-required={!!required}
                    aria-describedby={showError ? `${id}-error` : null}
                    width={inputWidth}
                />

                {activeRightButton && (
                    <InputRightElement width="4.5rem">
                        <IconButton
                            onClick={() => handleClickRightButton(customId, value)}
                            colorScheme="brand"
                            variant="ghost"
                            icon={FaSave}
                            _active={{
                                border: 'none',
                            }}
                            _hover={{
                                color: 'brandSecondary.600',
                            }}
                        />
                    </InputRightElement>
                )}
            </InputGroup>
        </FormGroup>
    );
};

FieldInput.propTypes = propTypes;
FieldInput.defaultProps = defaultProps;
